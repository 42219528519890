import React, { useState, Fragment } from 'react'
import Modal from 'react-bootstrap/Modal'
import { API_URL } from '../utils/Constants/URLS'
import { useNavigate } from 'react-router-dom'
const TerminateConfirmationModal = props => {
  const { leadId } = props
  const token = localStorage.getItem('token')
  const [openModal, setOpenModal] = useState(false)
  const [closingReason, setClosingReason] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const navigate = useNavigate()
  const handleCloseModal = () => {
    setShowSuccessModal(false)
    navigate("/view_leads")
  }

  const updateLead = async () => {
    try {
      const res = await fetch(`${API_URL}leads/${leadId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'Application/Json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          terminated: true,
          agreement: {
            stage: 'LOST',
            closingReason
          }
        })
      })
      const result = await res.json()
      if (result.error === false) {
        setOpenModal(false)
        setShowSuccessModal(true);
      }
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Fragment>
      <button
        name='terminateLead'
        className='btn btn-danger'
        type='button'
        onClick={() => setOpenModal(true)}
      >
        Terminate
      </button>
      <Modal show={openModal} onHide={handleCloseModal} centered>
        <Modal.Header className='bg-red-100' closeButton>
          <Modal.Title>Terminate Lead</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <form>
            <div class='my-8 text-center'>
              <textarea
                className='form-control h-full'
                value={closingReason}
                onChange={e => setClosingReason(e.target.value)}
                placeholder='Enter The Reason to Terminated The Lead'
              />
            </div>

            <div class='flex max-sm:flex-col gap-4'>
              <button
                onClick={handleCloseModal}
                type='button'
                class='px-5 py-2.5 rounded-lg w-full tracking-wide text-gray-800 text-sm border-none outline-none bg-gray-200 hover:bg-gray-300'
              >
                Cancel
              </button>
              <button
                type='button'
                onClick={updateLead}
                class='px-5 py-2.5 rounded-lg w-full tracking-wide text-white text-sm border-none outline-none bg-red-500 hover:bg-red-600'
              >
                Terminate
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={showSuccessModal} centered>
        <Modal.Body>
          <div class='my-8 text-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='w-14 shrink-0 fill-blue-500 inline'
              viewBox='0 0 512 512'
            >
              <path
                d='M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z'
                data-original='#000000'
              />
              <path
                d='M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z'
                data-original='#000000'
              />
            </svg>
            <h4 className='text-xl text-gray-800 font-semibold mt-4'>
              Job Done
            </h4>
            <p className='text-sm text-gray-500 leading-relaxed mt-4'>
              Lead Terminated Succesfully!
            </p>
          </div>

          <button
            type='button'
            onClick={handleCloseModal}
            className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-blue-500 hover:bg-blue-700'
          >
            Okay
          </button>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default TerminateConfirmationModal
