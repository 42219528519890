import React, { Fragment, useEffect, useState } from 'react'
import {
  Col,
  Row,
  Table,
  Label,
  Input,
  CardBody,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody
} from 'reactstrap'
import Select from 'react-select'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'
import { API_URL } from './utils/Constants/URLS'
import 'primereact/resources/themes/lara-light-cyan/theme.css'
import { useDispatch, useSelector } from 'react-redux'
import '../css/OrgContacts.css'
import '../css/common.css'
import { addDeals } from './utils/redux/dealsSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faPlus } from '@fortawesome/free-solid-svg-icons'
import PageHeader from './utils/PageHeader'
import PageLoader from './utils/PageLoader'
const ViewLeads = () => {
  const token = localStorage.getItem('token')
  const [showFilterCanvas, setShowFilterCanvas] = useState(false)
  const [searchLead, setSearchLead] = useState('')
  const [businessLineList, setBusinessLineList] = useState([])
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [clientDetails, setClientDetails] = useState([])
  const [ownerDetails, setOwnerDetails] = useState([])
  const [newExeId, setNewExeId] = useState('')
  const [changeLeadOwnerModal, setChangeLeadOwnerModal] = useState(false)
  const [leadId, setLeadId] = useState('')
  const [clientId, setClientId] = useState('') //for filters
  const [leadOwner, setLeadOwner] = useState('') //for filters
  const [businessLineId, setBusinessLineId] = useState([]) //for filters
  const [leadStage, setLeadStage] = useState([]) //for filters
  const [leadList, setLeadList] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  const [nextPage, setNextPage] = useState(null)
  const [prevPage, setPrevPage] = useState(null)
  const [pageLoader, setPageLoader] = useState('')
  const dispatch = useDispatch()
  const userDetails = useSelector(store => store.user)
  const addDealToRedux = deal => {
    dispatch(addDeals(deal))
  }

  const closeFilterCanvas = () => {
    setShowFilterCanvas(false)
  }

  const handleAssignLead = lead_id => {
    setLeadId(lead_id)
    setChangeLeadOwnerModal(true)
  }

  const updateLeadOwner = async e => {
    e.preventDefault()
    try {
      const res = await fetch(`${API_URL}leads/${leadId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'Application/Json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          leadOwner: newExeId
        })
      })
      const result = await res.json()
      if (result.error === false) {
        setChangeLeadOwnerModal(false)
        setShowSuccessModal(true)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleFilteredBusinessLine = event => {
    const { value, checked } = event.target

    if (checked) {
      // Add the value to the state if the checkbox is checked
      setBusinessLineId(prevValues => [...prevValues, value])
    } else {
      // Remove the value from the state if the checkbox is unchecked
      setBusinessLineId(prevValues => prevValues.filter(v => v !== value))
    }
  }

  const handleFilteredStage = event => {
    const { value, checked } = event.target

    if (checked) {
      // Add the value to the state if the checkbox is checked
      setLeadStage(prevValues => [...prevValues, value])
    } else {
      // Remove the value from the state if the checkbox is unchecked
      setLeadStage(prevValues => prevValues.filter(v => v !== value))
    }
  }

  useEffect(() => {
    const fetchLead = async () => {
      setPageLoader(true)
      try {
        const url = `${API_URL}leads?limit=5&page=${pageNumber}${
          searchLead === '' ? '' : '&search=' + searchLead
        }${clientId === '' ? '' : '&client=' + clientId}${
          businessLineId.length === 0
            ? ''
            : '&businessLine=' + businessLineId.join(',')
        }${leadStage.length === 0 ? '' : '&status=' + leadStage.join(',')}&${
          leadOwner === '' ? '' : 'leadOwner=' + leadOwner
        }`
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/Json',
            Authorization: `Bearer ${token}`
          }
        })
        const response = await res.json()
        setLeadList(response?.data?.docs)
        setTotalPages(response?.data?.totalPages)
        setNextPage(response?.data?.nextPage)
        setPrevPage(response?.data?.prevPage)
      } catch (e) {
        console.log(e)
      } finally {
        setPageLoader(false)
      }
    }

    fetchLead()
  }, [
    token,
    searchLead,
    pageNumber,
    clientId,
    businessLineId,
    leadStage,
    leadOwner
  ])

  useEffect(() => {
    const fetchUser = async () => {
      const url = `${API_URL}users/org-contacts?pagination=false`
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/Json',
          Authorization: `Bearer ${token}`
        }
      })
      const response = await res.json()
      setOwnerDetails(
        response?.data?.docs?.map(item => ({
          value: item?.id,
          label: item?.name
        }))
      )
    }
    fetchUser()
  }, [token])

  useEffect(() => {
    const fetchClients = async () => {
      const url = `${API_URL}clients?pagination=false`
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/Json',
          Authorization: `Bearer ${token}`
        }
      })
      const response = await res.json()
      setClientDetails(
        response?.data?.docs?.map(item => ({
          value: item?.id,
          label: item?.companyName
        }))
      )
    }
    fetchClients()
  }, [token])

  useEffect(() => {
    const fetchBusinessLines = async () => {
      try {
        const url = `${API_URL}business-lines?limit=100`
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        const result = await res.json()
        setBusinessLineList(result.data.docs)
      } catch (error) {
        console.error(error)
      }
    }

    fetchBusinessLines()
  }, [token])

  return (
    <Fragment>
      <div className='w-100'>
        <PageHeader
          pageName='VIEW LEADS'
          breadcrump1='Home'
          breadcrump2='View Leads'
          link='/dashboard'
        />
        <div className='main lg:p-6 xl:p-6 2xl:p-6 sm:p-3 md:p-3 '>
          <CardBody className='bg-white p-3'>
            <form>
              <Row>
                <Col lg={3} md={3} sm={6} xs={6}>
                  <div>
                    <input
                      className='form-control'
                      type='text'
                      onChange={e => setSearchLead(e.target.value)}
                      placeholder='Search Here'
                    />
                  </div>
                </Col>
                <Col
                  lg={9}
                  md={9}
                  sm={6}
                  xs={6}
                  className='flex-wrap d-none d-md-block'
                >
                  <div className='lg:float-right xl:float-right 2xl:float-right flex'>
                    {userDetails.role === 'executive' ||
                    userDetails.role === 'manager' ? (
                      <div>
                        <Link to='/lead'>
                          <button
                            type='button'
                            className='btn add-btn text-white mr-2'
                          >
                            +Add Lead
                          </button>
                        </Link>
                      </div>
                    ) : (
                      <div>
                        <button
                          type='button'
                          onClick={() => setShowFilterCanvas(true)}
                          className='btn filter-btn mr-2 text-white'
                        >
                          <FontAwesomeIcon className='mr-3' icon={faList} />{' '}
                          Filters
                        </button>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
          <CardBody>
            <div className='table-responsive w-full  mb-3 '>
              <Table hover className='align-middle table-nowrap'>
                <thead className='table-light'>
                  <tr>
                    <th>
                      <div className='flex items-center'>Opportunity ID</div>
                    </th>
                    <th>
                      <div className='flex items-center'>Name</div>
                    </th>
                    <th>
                      <div className='flex items-center'>Client Name</div>
                    </th>
                    <th>
                      <div className='flex items-center'>Value</div>
                    </th>
                    <th>
                      <div className='flex items-center'>Business Line</div>
                    </th>
                    <th>
                      <div className='flex items-center'>Expt Closure</div>
                    </th>
                    <th>
                      <div className='flex items-center'>Owner</div>
                    </th>
                    <th>
                      <div className='flex items-center'>Status</div>
                    </th>
                    {userDetails.role === 'admin' ||
                    userDetails.role === 'super_admin' ||
                    userDetails.role === 'manager' ? (
                      <th colSpan='2'>Action</th>
                    ) : (
                      ''
                    )}
                  </tr>
                </thead>

                <tbody>
                  {pageLoader && <PageLoader/>}
                  {leadList.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {userDetails.role === 'manager' ||
                          userDetails.role === 'executive' ? (
                            <Link
                              to={
                                data.agreement.stage === 'LOST' ||
                                data.agreement.stage === 'WON' ||
                                data.agreement.stage === 'DROP' ||
                                data.agreement.stage === 'TERMINATED'
                                  ? `/lead_details/${data.id}`
                                  : `/leads/${data.id}`
                              }
                              className='text-dark no-underline'
                              onClick={() => addDealToRedux(data)}
                            >
                              {data.leadId}
                            </Link>
                          ) : (
                            <Fragment>
                              <Link
                                className='text-dark no-underline'
                                to={`/lead_details/${data.id}`}
                              >
                                {data.leadId}
                              </Link>
                            </Fragment>
                          )}
                        </td>
                        <td>{data?.leadJourneyName}</td>
                        <td>{data?.client?.companyName}</td>
                        <td>{data?.productRequirements?.proposedDealAmount}</td>
                        <td>{data?.businessLine?.name}</td>
                        <td>
                          {data?.enquiryDetails?.tentativeClosureDate?.substring(
                            0,
                            10
                          )}
                        </td>
                        <td>{data?.leadOwner?.name}</td>
                        <td>
                          <span
                            className={
                              data?.agreement?.stage === 'LOST' ||
                              data?.agreement?.stage === 'DROP' ||
                              data.agreement.stage === 'TERMINATED'
                                ? 'text-red-600'
                                : data?.agreement?.stage === 'WON'
                                ? 'text-green-500'
                                : 'text-blue-600'
                            }
                          >
                            {(data?.leadStatus || 'In Progress').toUpperCase()}
                          </span>
                        </td>
                        {userDetails.role === 'admin' ||
                        userDetails.role === 'super_admin' ||
                        userDetails.role === 'manager' ? (
                          <td>
                            <span className='action-column'>
                              <div>
                                <Link to='#' className='text-dark no-underline'>
                                  <FontAwesomeIcon
                                    className='ml-1'
                                    onClick={() => handleAssignLead(data?.id)}
                                    icon={faPlus}
                                  />
                                </Link>
                              </div>
                            </span>
                          </td>
                        ) : (
                          ''
                        )}
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
          <CardBody className='float-right'>
            <nav className='ml-5' aria-label='...'>
              <ul className='pagination'>
                <li className='page-item'>
                  <Link
                    onClick={() => setPageNumber(prevPage)}
                    className='page-link text-primary'
                    tabindex='-1'
                  >
                    Previous
                  </Link>
                </li>
                {[...Array(totalPages)].map((_, index) => {
                  return (
                    <li className='page-item'>
                      <Link
                        onClick={() => setPageNumber(index + 1)}
                        className='page-link text-primary'
                        to='#'
                      >
                        {index + 1}
                      </Link>
                    </li>
                  )
                })}
                <li className='page-item'>
                  <Link
                    onClick={() => setPageNumber(nextPage)}
                    className='page-link text-primary'
                    to='#'
                  >
                    Next
                  </Link>
                </li>
              </ul>
            </nav>
          </CardBody>
        </div>
        <Offcanvas
          direction='end'
          isOpen={showFilterCanvas}
          id='offcanvasExample'
          toggle={closeFilterCanvas}
        >
          <OffcanvasHeader className='bg-light' toggle={closeFilterCanvas}>
            Leads Fliters
          </OffcanvasHeader>
          <form
            action=''
            className='d-flex flex-column justify-content-end h-100'
          >
            <OffcanvasBody>
              <div className='mb-4'>
                <Label
                  htmlFor='datepicker-range'
                  className='form-label text-muted text-uppercase fw-semibold mb-3'
                >
                  Owner
                </Label>
                <Select
                  options={ownerDetails}
                  onChange={e => setLeadOwner(e.value)}
                  className='mb-0'
                  id='country-select'
                ></Select>
              </div>
              <div className='mb-4'>
                <Label
                  htmlFor='country-select'
                  className='form-label text-muted text-uppercase fw-semibold mb-3'
                >
                  Client
                </Label>

                <Select
                  options={clientDetails}
                  onChange={e => setClientId(e.value)}
                  className='mb-0'
                  id='country-select'
                ></Select>
              </div>
              <div className='mb-4'>
                <Label
                  htmlFor='status-select'
                  className='form-label text-muted text-uppercase fw-semibold mb-3'
                >
                  Status
                </Label>
                <Row className='g-2'>
                  <Col lg={6}>
                    <div className='form-check'>
                      <Input
                        className='form-check-input'
                        type='checkbox'
                        name='checkbox1'
                        value='won'
                        onChange={handleFilteredStage}
                      />
                      <Label
                        className='form-check-label'
                        htmlFor='inlineCheckbox1'
                      >
                        WON
                      </Label>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className='form-check'>
                      <Input
                        className='form-check-input'
                        type='checkbox'
                        name='checkbox1'
                        value='partial won'
                        onChange={handleFilteredStage}
                      />
                      <Label
                        className='form-check-label'
                        htmlFor='inlineCheckbox2'
                      >
                        PARTIAL WON
                      </Label>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className='form-check'>
                      <Input
                        className='form-check-input'
                        type='checkbox'
                        name='checkbox1'
                        value='lost'
                        onChange={handleFilteredStage}
                      />
                      <Label
                        className='form-check-label'
                        htmlFor='inlineCheckbox3'
                      >
                        LOST
                      </Label>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className='form-check'>
                      <Input
                        className='form-check-input'
                        type='checkbox'
                        name='checkbox1'
                        value='drop'
                        onChange={handleFilteredStage}
                      />
                      <Label
                        className='form-check-label'
                        htmlFor='inlineCheckbox4'
                      >
                        DROP
                      </Label>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Label
                  htmlFor='leads-tags'
                  className='form-label text-muted text-uppercase fw-semibold mb-3'
                >
                  Business Lines
                </Label>
                <Row className='g-3'>
                  {businessLineList.map((data, index) => {
                    return (
                      <Col lg={6} key={index}>
                        <div className='form-check'>
                          <Input
                            className='form-check-input'
                            name='checkbox1'
                            type='checkbox'
                            value={data?.id}
                            onChange={handleFilteredBusinessLine}
                          />
                          <Label
                            className='form-check-label'
                            htmlFor='marketing'
                          >
                            {data?.name}
                          </Label>
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              </div>
            </OffcanvasBody>
          </form>
        </Offcanvas>
        <Modal
          show={changeLeadOwnerModal}
          onHide={() => setChangeLeadOwnerModal(false)}
          centered
        >
          <Modal.Header className='bg-indigo-200' closeButton>
            <Modal.Title>Change Owner</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <form className='row g-3 needs-validation' noValidate>
                <div className='col-md-12'>
                  <label htmlFor='name' className='form-label'>
                    Select Executive
                  </label>
                  <Select
                    options={ownerDetails}
                    onChange={e => setNewExeId(e.value)}
                  />
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={updateLeadOwner}
              className='btn add-btn text-white'
            >
              Assign
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={showSuccessModal} centered>
          <Modal.Body>
            <div class='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 shrink-0 fill-green-500 inline'
                viewBox='0 0 512 512'
              >
                <path
                  d='M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z'
                  data-original='#000000'
                />
                <path
                  d='M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z'
                  data-original='#000000'
                />
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                Job Done
              </h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                Lead Owner Changed Successfully!
              </p>
            </div>

            <button
              type='button'
              onClick={() => setShowSuccessModal(false)}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-green-500 hover:bg-green-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  )
}

export default ViewLeads
