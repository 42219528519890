import React, { Fragment, useEffect, useState } from 'react'
import PageHeader from './utils/PageHeader'
import { Card, CardBody, CardHeader, Row, Col, CardFooter } from 'reactstrap'
import Select from 'react-select'
import { API_URL } from './utils/Constants/URLS'

const AddExpense = () => {
  const token = localStorage.getItem('token')
  const [leadData, setLeadData] = useState([{}])
  useEffect(() => {
    const fetchLead = async () => {
      const res = await fetch(`${API_URL}leads?pagination=false`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      const result = await res.json()
      setLeadData(
        result?.data?.docs.map(item => ({
          value: item?.id,
          label: item?.leadJourneyName
        }))
      )
    }
    fetchLead()
  }, [token])

  return (
    <Fragment>
      <PageHeader
        pageName='CLIENT PROFILE'
        breadcrump1='Home'
        breadcrump2='Client Profile'
        link='/dashboard'
      />
      <div className='p-5'>
        <Card>
          <CardHeader>Add Expenses</CardHeader>
          <CardBody>
            <Row>
              <Col md={4}>
                <label>Select Lead</label>
                <Select options={leadData} />
              </Col>
              <Col md={8}>
                <Row>
                  <Col md={12}>
                    <label>Expense Type</label>
                    <select className='form-select'>
                      <option>Select</option>
                      <option>Travel</option>
                      <option>Food</option>
                      <option>Acomodation</option>
                      <option>Others</option>
                    </select>
                  </Col>
                  <Col md={12} className='mt-2'>
                    <label>Date</label>
                    <input
                      type='date'
                      onClick={e =>
                        e.target.showPicker && e.target.showPicker()
                      }
                      className='form-control'
                    />
                  </Col>
                  <Col md={12} className='mt-2'>
                    <label>Amount</label>
                    <input type='number' className='form-control' />
                  </Col>
                  <Col md={12} className='mt-2'>
                    <label>File</label>
                    <input type='file' className='form-control' />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter><button className='btn add-btn text-white float-right'>Add</button></CardFooter>
        </Card>
      </div>
    </Fragment>
  )
}

export default AddExpense
