import React, { Fragment, useEffect, useRef, useState } from 'react'
import 'primereact/resources/themes/lara-light-cyan/theme.css'
import { Toast } from 'primereact/toast'
import Modal from 'react-bootstrap/Modal'
import IndustryList from '../../utils/Constants/IndustryList'
import { API_URL } from '../../utils/Constants/URLS'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { useSelector } from 'react-redux'

const ProductRequirement = props => {
  const { id } = props
  const toast = useRef(null)
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const [modalMessage, setModalMessage] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const [productLine, setProductLine] = useState('')
  const [modelId, setModelId] = useState('')
  const [productLineList, setProductLineList] = useState([{}])
  const [models, setModel] = useState([{}])
  const [typeOfSale, setTypeOfSale] = useState('')
  const [natureOfSale, setNatureOfSale] = useState('')
  const [leadSource, setLeadSource] = useState('')
  const [industry, setIndustry] = useState('')
  const [description, setDescription] = useState('')
  const [proposedDealAmount, setProposedDealAmount] = useState(0)
  const [enquiryDetails, setEnquiryDetails] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)
  const [doesAnyProductAdeed, setDoesAnyProductAdded] = useState(false)
  const leadDetails = useSelector(store => store.deal)
  const userDetails = useSelector(store => store.user)

  const handleCloseModal = () => {
    setShowSuccessModal(false)
    setShowDeclineModal(false)
    navigate('/view_leads')
  }

  const updateProductRequirement = async e => {
    e.preventDefault()
    if (typeOfSale === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select Type of Sale',
        life: 3000
      })
    } else if (natureOfSale === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select Nature of Sale',
        life: 3000
      })
    } else if (industry === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select The Industry',
        life: 3000
      })
    } else if (enquiryDetails === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter a Proper Enquiry Details',
        life: 3000
      })
    } else if (leadSource === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter a Proper Lead Source',
        life: 3000
      })
    } else if (description === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter a Proper Description',
        life: 3000
      })
    } else {
      try {
        const res = await fetch(`${API_URL}leads/${id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            productRequirements: {
              typeOfSale,
              natureOfSale,
              industry,
              enquiryDetails,
              leadSource,
              proposedDealAmount,
              description,
              status: 'done'
            }
          })
        })
        const response = await res.json()
        if (response.error === false) {
          setModalMessage('Product Requirement Updated Successfully')
          setShowSuccessModal(true)
        } else {
          setModalMessage('Something Went Wrong')
          setShowDeclineModal(true)
        }
      } catch (e) {
        setModalMessage('Oops! Unable to add data')
        setShowDeclineModal(true)
      }
    }
  }

  useEffect(() => {
    const fetchProductLines = async () => {
      try {
        const res = await fetch(
          `${API_URL}product-lines?pagination=false&businessLine=${userDetails.businessLine}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'Application/json',
              Authorization: `Bearer ${token}`
            }
          }
        )
        const result = await res.json()
        setProductLineList(
          result.data.docs.map(product => ({
            value: product.id, // or the unique identifier for the product
            label: product.productLineName // or the display name for the product
          }))
        )
      } catch (e) {
        console.log(e)
      }
    }
    fetchProductLines()
  }, [token])

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const res = await fetch(
          `${API_URL}models?pagination=false&productLine=${productLine}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'Application/json',
              Authorization: `Bearer ${token}`
            }
          }
        )
        const result = await res.json()
        console.log(result.data.docs)
        setModel(
          result.data.docs.map(model => ({
            value: model?.id, // or the unique identifier for the product
            label: model?.modelName // or the display name for the product
          }))
        )
      } catch (e) {
        console.log(e)
      }
    }
    if (productLine !== '') {
      fetchModels()
    }
  }, [token, productLine])

  const handleShowDiv = () => {
    setShowSuccess(true)
    setTimeout(() => {
      setShowSuccess(false)
      setProductLine('')
      setModelId('')
    }, 3000)
  }

  const addCrossSell = async e => {
    e.preventDefault()
    try {
      const res = await fetch(`${API_URL}cross-sells/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          productLine,
          model: modelId,
          value: proposedDealAmount,
          leadId: leadDetails.id
        })
      })
      const result = await res.json()
      if (result.error === false) {
        handleShowDiv()
        setDoesAnyProductAdded(true)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Fragment>
      <div>
        <form className='row g-3 needs-validation'>
          <div className='row ms-1 p-3'>
            <div className='col-md-4'>
              <label for='name' className='form-label'>
                Type of Sale <span className='text-danger'>*</span>
              </label>
              <select
                name='typeOfSale'
                required
                value={typeOfSale}
                onChange={e => setTypeOfSale(e.target.value)}
                data-section='productRequirements'
                className='form-select'
              >
                <option value=''>Select Type</option>
                <option value='Domestic'>Domestic</option>
                <option value='Export'>Export</option>
              </select>
            </div>
            <div className='col-md-4'>
              <label for='name' className='form-label'>
                Nature of Sale <span className='text-danger'>*</span>
              </label>
              <select
                name='natureOfSale'
                required
                value={natureOfSale}
                onChange={e => setNatureOfSale(e.target.value)}
                data-section='productRequirements'
                className='form-select'
              >
                <option value=''>Select Type</option>
                <option value='Product'>Product</option>
                <option value='Project'>Project</option>
              </select>
            </div>
            <div className='col-md-4'>
              <label for='name' className='form-label'>
                Industy <span className='text-danger'>*</span>
              </label>
              <select
                name='industry'
                required
                value={industry}
                onChange={e => setIndustry(e.target.value)}
                data-section='productRequirements'
                className='form-select'
              >
                {IndustryList.map((data, index) => {
                  return <option key={index}>{data}</option>
                })}
              </select>
            </div>
          </div>
          <div className='row ms-1 p-3'>
            <div className='col-md-6'>
              <label for='enquiry-details' className='form-label'>
                Enquiry Details <span className='text-danger'>*</span>
              </label>
              <textarea
                name='enquiryDetails'
                type='text'
                className='form-control'
                value={enquiryDetails}
                onChange={e => setEnquiryDetails(e.target.value)}
                data-section='productRequirements'
                id='enquiry-details'
                required
              />
            </div>
            <div className='col-md-6'>
              <label for='lead-source' className='form-label'>
                Lead Source <span className='text-danger'>*</span>
              </label>
              <textarea
                name='leadSource'
                type='text'
                className='form-control'
                value={leadSource}
                onChange={e => setLeadSource(e.target.value)}
                data-section='productRequirements'
                id='leadSource'
                required
              />
            </div>
          </div>

          <form className='row ms-1 p-3'>
            <div className='col-md-4'>
              <label for='product-line' className='form-label'>
                Product Line <span className='text-danger'>*</span>
              </label>
              <Select
                options={productLineList}
                onChange={e => setProductLine(e.value)}
              />
            </div>

            <div className='col-md-4'>
              <label for='model' className='form-label'>
                Model <span className='text-danger'>*</span>
              </label>
              {productLine === '' ? (
                <input type='text' className='form-control' readOnly />
              ) : (
                <Select options={models} onChange={e => setModelId(e.value)} />
              )}
            </div>
            <div className='col-md-2'>
              <label for='enquiry-details' className='form-label'>
                Amount <span className='text-danger'>*</span>
              </label>
              <input
                type='number'
                value={proposedDealAmount}
                onChange={e => setProposedDealAmount(e.target.value)}
                className='form-control'
                required
              />
            </div>
            <div className='col-md-2 pt-2'>
              <button
                type='button'
                onClick={addCrossSell}
                className='btn mt-4 add-btn text-white'
              >
                Add
              </button>
            </div>
            {showSuccess && (
              <div
                className={`col-md-12 mt-2 p-3 fade-out bg-green-100 text-green-600`}
              >
                Product Added to the list...
              </div>
            )}
          </form>
          <Fragment>
            <div className='row ms-1 p-3'>
              <div className='col-md-12'>
                <label for='description' className='form-label'>
                  Description <span className='text-danger'>*</span>
                </label>
                <textarea
                  name='description'
                  type='text'
                  className='form-control'
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  data-section='productRequirements'
                  id='description'
                  required
                />
              </div>
            </div>
            <div className='col-md-12 d-flex justify-content-end'>
              <button
                onClick={updateProductRequirement}
                className='btn add-btn text-white'
                type='submit'
              >
                Next
              </button>
            </div>
          </Fragment>
        </form>
        <Modal show={showSuccessModal} centered>
          <Modal.Body>
            <div class='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 shrink-0 fill-green-500 inline'
                viewBox='0 0 512 512'
              >
                <path
                  d='M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z'
                  data-original='#000000'
                />
                <path
                  d='M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z'
                  data-original='#000000'
                />
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                Good Job
              </h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                {modalMessage}!
              </p>
            </div>

            <button
              type='button'
              onClick={handleCloseModal}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-green-500 hover:bg-green-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
        <Modal show={showDeclineModal} centered>
          <Modal.Body>
            <div className='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 fill-red-500 inline'
                viewBox='0 0 286.054 286.054'
              >
                <path
                  fill='#e2574c'
                  d='M143.027 0C64.04 0 0 64.04 0 143.027c0 78.996 64.04 143.027 143.027 143.027 78.996 0 143.027-64.022 143.027-143.027C286.054 64.04 222.022 0 143.027 0zm0 259.236c-64.183 0-116.209-52.026-116.209-116.209S78.844 26.818 143.027 26.818s116.209 52.026 116.209 116.209-52.026 116.209-116.209 116.209zm.009-196.51c-10.244 0-17.995 5.346-17.995 13.981v79.201c0 8.644 7.75 13.972 17.995 13.972 9.994 0 17.995-5.551 17.995-13.972V76.707c-.001-8.43-8.001-13.981-17.995-13.981zm0 124.997c-9.842 0-17.852 8.01-17.852 17.86 0 9.833 8.01 17.843 17.852 17.843s17.843-8.01 17.843-17.843c-.001-9.851-8.001-17.86-17.843-17.86z'
                  data-original='#e2574c'
                ></path>
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                Error Occured
              </h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                {modalMessage}!
              </p>
            </div>

            <button
              type='button'
              onClick={handleCloseModal}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-red-500 hover:bg-red-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
        <Toast ref={toast} />
      </div>
    </Fragment>
  )
}

export default ProductRequirement
