import React, { Fragment, useState, useEffect } from 'react'
import '../../css/SubProgressBar.css'
import { TabContent, TabPane } from 'reactstrap'
import Form from 'react-bootstrap/Form'
import SearchExistingCompany from '../Forms/FormComponents/SearchExistingCompany'
import ProductRequirement from '../Forms/FormComponents/ProductRequirement'
import EnquiryDetails from '../Forms/FormComponents/EnquiryDetails'
import MarketingPlatform from '../Forms/FormComponents/MarketingPlatform'
import WorkingPlatform from '../Forms/FormComponents/WorkingPlatform'
import BuyingPlatform from '../Forms/FormComponents/BuyingPlatform'
import AddClient from '../Forms/FormComponents/AddClient'

const SubProgessBar = props => {
  const { itemOne, itemTwo, itemThree, id, initialActiveTab } = props
  const [activeButton, setActiveButton] = useState(initialActiveTab)
  const [existingClient, setExistingClient] = useState(false)

  useEffect(() => {
    setActiveButton(initialActiveTab)
  }, [initialActiveTab])

  return (
    <Fragment>
      <div className='d-flex'>
        <button
          type='button'
          className={`sub-progressbar-btn
            ${
              activeButton === 'Client' || activeButton === 'Marketing'
                ? 'active'
                : itemOne === 'Client (Done)' || itemOne === 'Marketing (Done)'
                ? 'done'
                : ''
            }`}
        >
          <span className='text-lg'>{itemOne.split(' ')[0]}</span>
        </button>

        <button
          type='button'
          className={`sub-progressbar-btn ${
            activeButton === 'Product' || activeButton === 'Working'
              ? 'active'
              : itemTwo === 'Product (Done)' || itemTwo === 'Working (Done)'
              ? 'done'
              : ''
          }`}
        >
          <span className='text-lg'>{itemTwo.split(' ')[0]}</span>
        </button>

        <button
          type='button'
          className={`sub-progressbar-btn ${
            activeButton === 'Enquiry' || activeButton === 'Buying'
              ? 'active'
              : ''
          }`}
        >
          <span className='text-lg'>{itemThree.split(' ')[0]}</span>
        </button>
      </div>

      <div className='mt-3'>
        <TabContent activeTab={activeButton}>
          <TabPane tabId={itemOne}>
            {itemOne === 'Client' ? (
              <div>
                <div>
                  <Form>
                    <label class='inline-flex items-center cursor-pointer'>
                      <input
                        type='checkbox'
                        value=''
                        class='sr-only peer'
                        onChange={e => setExistingClient(e.target.checked)}
                      />
                      <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      <span class='ms-3 text-sm font-medium text-gray-900 dark:text-gray-300'>
                        Existing Client?
                      </span>
                    </label>
                  </Form>
                </div>

                <div className='mt-3'>
                  {existingClient ? (
                    <SearchExistingCompany />
                  ) : (
                    <AddClient assignToLead={true} />
                  )}
                </div>
              </div>
            ) : (
              <MarketingPlatform />
            )}
          </TabPane>
          <TabPane tabId={itemTwo}>
            {itemTwo === 'Product' ? (
              <ProductRequirement id={id} />
            ) : (
              <WorkingPlatform />
            )}
          </TabPane>
          <TabPane tabId={itemThree}>
            {itemThree === 'Enquiry' ? (
              <EnquiryDetails id={id} />
            ) : (
              <BuyingPlatform />
            )}
          </TabPane>
        </TabContent>
      </div>
    </Fragment>
  )
}

export default SubProgessBar
