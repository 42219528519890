import React, { Fragment, useState, useEffect, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import 'primereact/resources/themes/lara-light-cyan/theme.css'
import { Toast } from 'primereact/toast'
import InputGroup from 'react-bootstrap/InputGroup'
import Select from 'react-select'
import { API_URL } from '../utils/Constants/URLS'
import { useSelector } from 'react-redux'
import '../../css/style.scss'
import PageLoader from '../utils/PageLoader'

export const AddEmpModal = () => {
  const userDetails = useSelector(store => store.user)
  const toast = useRef(null)
  const [showModal, setShowModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  let [email, setEmail] = useState('')
  const [phoneNumberOptional, setPhoneNumberOptional] = useState(null)
  const [pageLoader, setPageLoader] = useState('')
  const [businessArea, setBusinessArea] = useState(null)
  const [businessLine, setBusinessLine] = useState('')
  const [businessLineList, setBusinessLineList] = useState([{}])
  const [errorMsg, setErrorMsg] = useState('')
  const [role, setRole] = useState(
    userDetails.role === 'super_admin'
      ? 'admin'
      : userDetails.role === 'admin'
      ? 'manager'
      : 'executive'
  )

  const token = localStorage.getItem('token')

  const businessAreaData = [
    { value: 'NB', label: 'NB' },
    { value: 'SB', label: 'SB' },
    { value: 'NE', label: 'NE' }
  ]

  const handleAddAdmin = async e => {
    e.preventDefault()
    setPageLoader(true)
    if (phoneNumberOptional === '') {
      setPhoneNumberOptional(null)
    }
    if (name === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter the Name',
        life: 3000
      })
    } else if (
      phoneNumber === '' ||
      /^(?:[6-9]\d{9})$/.test(phoneNumber) === false
    ) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter a Valid Phone Number',
        life: 3000
      })
    } else if (
      phoneNumberOptional !== null &&
      /^(?:[6-9]\d{9})$/.test(phoneNumberOptional) === false
    ) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter a Valid Alternate Phone Number',
        life: 3000
      })
    } else if (email === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter the Email',
        life: 3000
      })
    } else {
      email = email.concat('@greenergy.net.in')
      try {
        const url = `${API_URL}users/admin`
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            name,
            email,
            phoneNumber,
            phoneNumberOptional
          })
        })
        const result = await res.json()
        if (result.error === false) {
          setShowSuccessModal(true)
          setShowModal(false)
        } else {
          setErrorMsg(result.message)
          setShowDeclineModal(true)
          setShowModal(false)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setPageLoader(false)
      }
    }
  }

  const handleAddResource = async e => {
    e.preventDefault()
    setPageLoader(true)
    if (name === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter the Name',
        life: 3000
      })
    } else if (
      phoneNumber === '' ||
      /^(?:[6-9]\d{9})$/.test(phoneNumber) === false
    ) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter a Valid Phone Number',
        life: 3000
      })
    } else if (
      phoneNumberOptional !== null &&
      /^(?:[6-9]\d{9})?$/.test(phoneNumberOptional) === false
    ) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter a Valid Alternate Phone Number',
        life: 3000
      })
    } else if (email === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter the Email',
        life: 3000
      })
    } else if (businessLine === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Select a Business Line',
        life: 3000
      })
    } else if (businessArea === null) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Select a Business Area',
        life: 3000
      })
    } else {
      email = email.concat('@greenergy.net.in')
      try {
        const url = `${API_URL}users/${
          role === 'manager' ? 'manager' : 'executive'
        }`
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            name,
            email,
            phoneNumber,
            phoneNumberOptional,
            businessLine,
            businessArea
          })
        })
        const result = await res.json()
        if (result.error === false) {
          setShowSuccessModal(true)
          setShowModal(false)
        } else {
          setErrorMsg(result.message)
          setShowDeclineModal(true)
          setShowModal(false)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setPageLoader(false)
      }
    }
  }

  useEffect(() => {
    const fetchBusinessLines = async () => {
      const url = `${API_URL}business-lines?pagination=false`
      const data = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      const result = await data.json()
      setBusinessLineList(
        result.data.docs.map(item => ({
          value: item.id,
          label: item.name
        }))
      )
    }

    fetchBusinessLines()
  }, [token])
  const handleCloseModal = () => {
    setShowModal(false)
    setShowSuccessModal(false)
    setShowDeclineModal(false)
  }
  const handleShowModal = () => {
    setShowModal(true)
  }

  return (
    <Fragment>
      <Button
        type='button'
        color='success'
        className='btn add-btn'
        onClick={handleShowModal}
      >
        + Add Employee
      </Button>
      <div
        className='modal show'
        style={{ display: 'block', position: 'initial' }}
      >
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header className='bg-indigo-100' closeButton>
            <Modal.Title>Add Employee</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {pageLoader && <PageLoader />}
            <div>
              <form>
                <div className='row mb-3'>
                  <div className='col-md-12'>
                    <select
                      className='form-select'
                      value={role}
                      onChange={e => setRole(e.target.value)}
                    >
                      {userDetails?.role === 'super_admin' ? (
                        <Fragment>
                          <option value='admin'>Admin</option>
                          <option value='manager'>Manager</option>
                          <option value='executive'>Executive</option>
                        </Fragment>
                      ) : userDetails?.role === 'admin' ? (
                        <Fragment>
                          <option value='manager'>Manager</option>
                          <option value='executive'>Executive</option>
                        </Fragment>
                      ) : userDetails?.role === 'manager' ? (
                        <option value='executive'>Executive</option>
                      ) : (
                        ''
                      )}
                    </select>
                  </div>
                  <div className='col-md-12'>
                    <label>
                      Name <span className='text-danger'>*</span>
                    </label>
                    <input
                      required
                      className='form-control'
                      type='text'
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </div>
                  <div className='col-md-12'>
                    <label>
                      Phone Number <span className='text-danger'>*</span>
                    </label>
                    <input
                      required
                      className='form-control'
                      type='number'
                      value={phoneNumber}
                      onChange={e => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  <div className='col-md-12'>
                    <label>Alternate Phone Number</label>
                    <input
                      type='number'
                      className='form-control'
                      value={phoneNumberOptional}
                      onChange={e => setPhoneNumberOptional(e.target.value)}
                    />
                  </div>
                  <div className='col-md-12'>
                    <label>
                      Email <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group has-validation'>
                      <input
                        className='form-control'
                        type='text'
                        defaultValue={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                      <InputGroup.Text id='inputGroupPostpend'>
                        @greenergy.net.in
                      </InputGroup.Text>
                    </div>
                  </div>
                  {role === 'manager' || role === 'executive' ? (
                    <div className='col-md-12'>
                      <label>
                        Business Line <span className='text-danger'>*</span>
                      </label>
                      <Select
                        options={businessLineList}
                        onChange={e => setBusinessLine(e.value)}
                      />
                    </div>
                  ) : (
                    ''
                  )}

                  {role === 'manager' || role === 'executive' ? (
                    <div className='col-md-12'>
                      <label>
                        Business Area <span className='text-danger'>*</span>
                      </label>
                      <Select
                        onChange={selectedOptions =>
                          setBusinessArea(
                            selectedOptions
                              ? selectedOptions.map(option => option.label)
                              : []
                          )
                        }
                        options={businessAreaData}
                        isMulti={true}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='button'
              onClick={role === 'admin' ? handleAddAdmin : handleAddResource}
              className='btn add-btn text-white'
            >
              Add Employee
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showSuccessModal} centered>
          <Modal.Body>
            <div className='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 shrink-0 fill-green-500 inline'
                viewBox='0 0 512 512'
              >
                <path
                  d='M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z'
                  data-original='#000000'
                />
                <path
                  d='M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z'
                  data-original='#000000'
                />
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                Good Job
              </h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                Employee Added Succesfully!
              </p>
            </div>

            <button
              type='button'
              onClick={handleCloseModal}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-green-500 hover:bg-green-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
        <Modal show={showDeclineModal} centered>
          <Modal.Body>
            <div className='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 fill-red-500 inline'
                viewBox='0 0 286.054 286.054'
              >
                <path
                  fill='#e2574c'
                  d='M143.027 0C64.04 0 0 64.04 0 143.027c0 78.996 64.04 143.027 143.027 143.027 78.996 0 143.027-64.022 143.027-143.027C286.054 64.04 222.022 0 143.027 0zm0 259.236c-64.183 0-116.209-52.026-116.209-116.209S78.844 26.818 143.027 26.818s116.209 52.026 116.209 116.209-52.026 116.209-116.209 116.209zm.009-196.51c-10.244 0-17.995 5.346-17.995 13.981v79.201c0 8.644 7.75 13.972 17.995 13.972 9.994 0 17.995-5.551 17.995-13.972V76.707c-.001-8.43-8.001-13.981-17.995-13.981zm0 124.997c-9.842 0-17.852 8.01-17.852 17.86 0 9.833 8.01 17.843 17.852 17.843s17.843-8.01 17.843-17.843c-.001-9.851-8.001-17.86-17.843-17.86z'
                  data-original='#e2574c'
                ></path>
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                Sorry!
              </h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                {errorMsg}
              </p>
            </div>

            <button
              type='button'
              onClick={handleCloseModal}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-red-500 hover:bg-red-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
        <Toast ref={toast} />
      </div>
    </Fragment>
  )
}
