import React, { Fragment } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Card, CardHeader, CardBody } from 'reactstrap';

const PipeLineByStage = () => {
    const series = [
        {
            name: "Funnel Series",
            data: [1380, 1100, 990, 880, 740],
        },
    ];
    const options = {
        chart: {
            type: 'bar',
            height: 350,
        },
        colors: ['#2E93fA'],
        plotOptions: {
            bar: {
                borderRadius: 0,
                horizontal: true,
                barHeight: '80%',
                isFunnel: true,
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
            },
            dropShadow: {
                enabled: true,
            },
        },
        xaxis: {
            categories: [
                'Marketing Platform',
                'Product Requirement',
                'Buying Paltform',
                'Working Platform',
                'Enquiry Details'
            ],
        },
        legend: {
            show: false,
        },
    }
    return (
        <Fragment>
            <Card>
                <CardHeader>Pipe Line By Stage</CardHeader>
                <CardBody>
                    <ReactApexChart
                        dir="ltr" className="apex-charts"
                        series={series}
                        options={options}
                        type="bar"
                        height={350}
                    />
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default PipeLineByStage