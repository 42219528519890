import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../css/Sidebar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserGear,
  faPowerOff,
  faCreditCard
} from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { removeDeal } from './utils/redux/dealsSlice'
import { removeUser } from './utils/redux/userSlice'

const SidebarMobile = () => {
  const [open, setOpen] = useState(null)
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false)
  const sidebarRef = useRef(null)
  const toggleBtnRef = useRef(null)
  const crmRef = useRef(null)
  const crmRef1 = useRef(null)
  const crmRef2 = useRef(null)
  const userDetails = useSelector(store => store.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleDropdown = navItemName => {
    setOpen(open === navItemName ? null : navItemName)
  }

  const toggleMobileSidebar = () => {
    setMobileSidebarOpen(!isMobileSidebarOpen)
  }

  const handleLogout = () => {
    localStorage.removeItem('token')
    dispatch(removeDeal())
    dispatch(removeUser())
    navigate('/', { replace: true })
  }

  useEffect(() => {
    const handleSidebarClosing = e => {
      if (
        e?.target === toggleBtnRef.current ||
        e?.target === sidebarRef.current ||
        e?.target === crmRef.current ||
        e?.target === crmRef1.current ||
        e?.target === crmRef2.current
      ) {
        setMobileSidebarOpen(true)
      } else {
        setMobileSidebarOpen(false)
      }
    }
    document.addEventListener('click', handleSidebarClosing)
    // Cleanup listener on unmount
    return () => {
      document.removeEventListener('click', handleSidebarClosing)
    }
  })

  return (
    <>
      <button
        className='sm:hidden fixed top-4 left-4 z-50 text-gray-200 dark:text-white'
        onClick={toggleMobileSidebar}
      >
        <svg
          ref={toggleBtnRef}
          className='w-6 h-6'
          aria-hidden='true'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            clipRule='evenodd'
            fillRule='evenodd'
            d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
          ></path>
        </svg>
      </button>

      {isMobileSidebarOpen && (
        <div
          className='sidebar-overlay active'
          onClick={toggleMobileSidebar}
        ></div>
      )}

      <aside
        id='logo-sidebar'
        className={`fixed top-[60px] left-0 z-40 w-64 h-screen pt-10 transition-transform transform bg-white border-r border-gray-200 rounded-lg sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700 ${
          isMobileSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } shadow-sm`}
        aria-label='Sidebar'
      >
        <div
          ref={sidebarRef}
          className='h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800'
        >
          {isMobileSidebarOpen && (
            <button
              onClick={toggleMobileSidebar}
              className='absolute top-4 right-4 text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white'
              aria-label='Close sidebar'
            >
              <svg
                className='w-6 h-6'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
          )}

          <ul className='space-y-2 font-medium'>
            <li>
              <Link
                onClick={toggleMobileSidebar}
                to='/dashboard'
                className='flex items-center no-underline  p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group'
              >
                <svg
                  className='w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 22 21'
                >
                  <path d='M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z' />
                  <path d='M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z' />
                </svg>
                <span className='flex-1 ms-3 text-left rtl:text-right whitespace-nowrap'>
                  Dashboard
                </span>
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMobileSidebar}
                to='/org_contact'
                className='flex items-center no-underline  p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group'
              >
                <FontAwesomeIcon className='text-gray-500' icon={faUserGear} />
                <span className='flex-1 ms-3 text-left rtl:text-right whitespace-nowrap'>
                  CRM Resources
                </span>
              </Link>
            </li>
            <li>
              <button
                type='button'
                onClick={() => handleDropdown('CRM')}
                className='flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700'
                aria-controls='dropdown-example'
              >
                <svg
                  ref={crmRef1}
                  className='flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 18 18'
                >
                  <path d='M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z' />
                </svg>
                <span
                  ref={crmRef}
                  className='flex-1 ms-3 text-left rtl:text-right whitespace-nowrap'
                >
                  CRM
                </span>
                <svg
                  className={`w-4 h-4 ms-auto transition-transform ${
                    open === 'CRM' ? 'rotate-180' : ''
                  }`}
                  aria-hidden='true'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                  ref={crmRef2}
                >
                  <path
                    fillRule='evenodd'
                    d='M5.23 7.21a.75.75 0 011.06.02L10 10.946l3.71-3.71a.75.75 0 111.06 1.06l-4.24 4.24a.75.75 0 01-1.06 0l-4.24-4.24a.75.75 0 01.02-1.06z'
                    clipRule='evenodd'
                  />
                </svg>
              </button>
              {open === 'CRM' && (
                <ul id='dropdown-example' className='py-2 space-y-2'>
                  {userDetails.role === 'admin' ||
                  userDetails.role === 'super_admin' ? (
                    <li>
                      <Link
                        onClick={toggleMobileSidebar}
                        to='/business_line'
                        className='flex items-center w-full p-2 text-base no-underline text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700'
                      >
                        - Business Line
                      </Link>
                    </li>
                  ) : (
                    ' '
                  )}

                  <li>
                    <Link
                      onClick={toggleMobileSidebar}
                      to='/add_client_contact'
                      className='flex items-center w-full p-2 text-base no-underline text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700'
                    >
                      - Add Client
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={toggleMobileSidebar}
                      to='/client_contact'
                      className='flex items-center w-full p-2 text-base no-underline text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700'
                    >
                      - View Client
                    </Link>
                  </li>
                  {userDetails.role === 'manager' ||
                  userDetails.role === 'executive' ? (
                    <li>
                      <Link
                        onClick={toggleMobileSidebar}
                        to='/lead'
                        className='flex items-center w-full p-2 text-base no-underline text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700'
                      >
                        - New Lead
                      </Link>
                    </li>
                  ) : (
                    ''
                  )}
                  <li>
                    <Link
                      onClick={toggleMobileSidebar}
                      to='/view_leads'
                      className='flex items-center w-full p-2 text-base no-underline text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700'
                    >
                      - View Leads
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            {userDetails.role === 'executive' ? (
              <li>
                <Link
                  onClick={toggleMobileSidebar}
                  to='/org_contact'
                  className='flex items-center no-underline  p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group'
                >
                  <FontAwesomeIcon
                    className='text-gray-500'
                    icon={faCreditCard}
                  />
                  <span className='flex-1 ms-3 text-left rtl:text-right whitespace-nowrap'>
                    Add Expense
                  </span>
                </Link>
              </li>
            ) : (
              ''
            )}

            <li>
              <Link className='flex items-center no-underline  p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group'>
                <FontAwesomeIcon icon={faPowerOff} />
                <button
                  onClick={handleLogout}
                  className='flex-1 ms-3 text-left rtl:text-right whitespace-nowrap'
                >
                  Log Out
                </button>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </>
  )
}

export default SidebarMobile
