import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import Modal from 'react-bootstrap/Modal'
import { API_URL } from '../utils/Constants/URLS'
import '../../css/common.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faListCheck,
  faPen,
  faTrashCan
} from '@fortawesome/free-solid-svg-icons'
import { Table } from 'reactstrap'

const CrossSellModal = props => {
  const token = localStorage.getItem('token')
  const [showModal, setShowModal] = useState(false)
  const [launch, setLaunch] = useState(true)
  const [crossSellData, setCrossSellData] = useState([])
  const [prodStatus, setProdStatus] = useState('')
  const [prodIndex, setProdIndex] = useState(-1)
  const [productLine, setProductLine] = useState('')
  const [modelId, setModelId] = useState('')
  const [isEdit, setIsEdit] = useState(null)
  const [productLineList, setProductLineList] = useState([{}])
  const [models, setModel] = useState([{}])
  const [amount, setAmount] = useState(0)
  const [showSuccess, setShowSuccess] = useState(false)
  const [proposedDealAmount, setProposedDealAmount] = useState('')
  const [editedAmt, setEditedAmt] = useState('')

  const leadDetails = useSelector(store => store.deal)
  const userDetails = useSelector(store => store.user)

  useEffect(() => {
    const fetchProductLines = async () => {
      try {
        const res = await fetch(
          `${API_URL}product-lines?pagination=false&businessLine=${userDetails.businessLine}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'Application/json',
              Authorization: `Bearer ${token}`
            }
          }
        )
        const result = await res.json()
        setProductLineList(
          result.data.docs.map(product => ({
            value: product.id, // or the unique identifier for the product
            label: product.productLineName // or the display name for the product
          }))
        )
      } catch (e) {
        console.log(e)
      }
    }
    fetchProductLines()
  }, [token])

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const res = await fetch(
          `${API_URL}models?pagination=false&productLine=${productLine}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'Application/json',
              Authorization: `Bearer ${token}`
            }
          }
        )
        const result = await res.json()
        setModel(
          result.data.docs.map(model => ({
            value: model.id, // or the unique identifier for the product
            label: model.modelName // or the display name for the product
          }))
        )
      } catch (e) {
        console.log(e)
      }
    }
    if (productLine !== '') {
      fetchModels()
    }
  }, [token, productLine])

  const handleShowDiv = () => {
    setShowSuccess(true)
    setTimeout(() => {
      setShowSuccess(false)
    }, 3000)
  }

  const addCrossSell = async e => {
    e.preventDefault()
    try {
      const res = await fetch(`${API_URL}cross-sells/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          productLine,
          model: modelId,
          value: amount,
          leadId: leadDetails.id
        })
      })
      const result = await res.json()
      if (result.error === false) {
        handleShowDiv()
        setCrossSellData([...crossSellData, result.data])
        setLaunch(!launch)
        setProductLine('')
        setModelId('')
        setAmount('')
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleEditAmt = async (e, id) => {
    e.preventDefault()
    try {
      const res = await fetch(`${API_URL}cross-sells/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          value: editedAmt
        })
      })
      const result = await res.json()
      console.log(result)
    } catch (e) {
      console.log(e)
    }
  }
  const handleShowModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleUpdateCrossSell = async (e, value, id, sts, index) => {
    e.preventDefault()
    try {
      const res = await fetch(`${API_URL}cross-sells/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          status: sts
        })
      })
      const result = await res.json()
      setProdStatus(result.data.status)
      setProdIndex(index)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const fetchCrossSellData = async () => {
      try {
        const res = await fetch(
          `${API_URL}cross-sells?pagination=false&leadId=${leadDetails.id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'Application/json',
              Authorization: `Bearer ${token}`
            }
          }
        )
        const result = await res.json()
        if (result.error === false) {
          setCrossSellData(result?.data?.docs)
          const item = result?.data?.docs?.map(item =>
            item.status === 'added' ? item.value : 0
          )
          setProposedDealAmount(item.reduce((acc, current) => acc + current, 0))
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetchCrossSellData()
  }, [launch, prodStatus, prodIndex, token])

  useEffect(() => {
    const addProposedDealAmountToLead = async () => {
      if (proposedDealAmount !== '') {
        try {
          const res = await fetch(`${API_URL}leads/${leadDetails.id}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'Application/Json',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
              productRequirements: {
                proposedDealAmount
              }
            })
          })
          const result = await res.json()
        } catch (e) {
          console.log(e)
        }
      }
    }
    addProposedDealAmountToLead()
  }, [proposedDealAmount])

  return (
    <Fragment>
      <div className='d-flex'>
        <span className='z-30'>
          <FontAwesomeIcon
            onClick={handleShowModal}
            size='xl'
            className='mt-2'
            icon={faListCheck}
          />
        </span>
      </div>
      <Modal size='xl' show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header className='bg-indigo-200' closeButton>
          <Modal.Title>Product List</Modal.Title>
        </Modal.Header>

        <Modal.Body scrollable={true}>
          <div>
            <div className='p-2'>
              <form
                onSubmit={addCrossSell}
                className='row p-3 bg-light d-flex justify-content-between'
              >
                <div className='col-lg-4 col-md-12 col-sm-12'>
                  <Select
                    options={productLineList}
                    placeholder='Select Product Line'
                    onChange={e => setProductLine(e.value)}
                  />
                </div>

                <div className='col-lg-4 col-md-12 col-sm-12 cross-sell-form'>
                  {productLine === '' ? (
                    <input type='text' className='form-control' readOnly />
                  ) : (
                    <Select
                      options={models}
                      placeholder='Select Model'
                      onChange={e => setModelId(e.value)}
                    />
                  )}
                </div>
                <div className='col-lg-2 col-md-12 col-sm-12 cross-sell-form'>
                  <input
                    type='number'
                    value={amount}
                    placeholder='Enter Value'
                    onChange={e => setAmount(e.target.value)}
                    className='form-control'
                    required
                  />
                </div>
                <div className='col-lg-2 col-md-12 col-sm-12 cross-sell-form'>
                  <button type='submit' className='btn add-btn text-white'>
                    Add Product
                  </button>
                </div>
                {showSuccess && (
                  <div
                    className={`col-md-12 mt-2 p-3 fade-out bg-green-100 text-green-600`}
                  >
                    Product Added to the list...
                  </div>
                )}
              </form>
            </div>
            <div className='table-responsive sm:overflow-auto sm:max-h-[400px] w-full  mb-3 '>
              <Table>
                <thead className='table-info'>
                  <tr>
                    <th>Product Line</th>
                    <th>Model</th>
                    <th>Amount</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {crossSellData.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{data.productLine.productLineName}</td>
                        <td>{data.model.modelName}</td>
                        <td>
                          {isEdit === index ? (
                            <form
                              className='d-flex'
                              onSubmit={e => handleEditAmt(e, data.id)}
                            >
                              <input
                                className='form-control'
                                value={editedAmt}
                                onChange={e => setEditedAmt(e.target.value)}
                              />
                              <button
                                type='submit'
                                className='ml-1 btn add-btn text-white'
                              >
                                Edit
                              </button>
                            </form>
                          ) : (
                            <Fragment>{data.value}</Fragment>
                          )}
                        </td>
                        <td className='d-flex'>
                          {data.status === 'added' ? (
                            <FontAwesomeIcon
                              size='xs'
                              onClick={e =>
                                handleUpdateCrossSell(
                                  e,
                                  data.value,
                                  data.id,
                                  'deleted',
                                  index
                                )
                              }
                              className='p-2 bg-red-200 text-red-600'
                              icon={faTrashCan}
                            />
                          ) : (
                            <FontAwesomeIcon
                              size='xs'
                              onClick={e =>
                                handleUpdateCrossSell(
                                  e,
                                  data.value,
                                  data.id,
                                  'added',
                                  index
                                )
                              }
                              className='p-2 bg-green-200 text-green-600'
                              icon={faCheck}
                            />
                          )}
                          <FontAwesomeIcon
                            size='xs'
                            className='p-2 ml-2 bg-[#e5f6fb] text-[#50c3e6]'
                            icon={faPen}
                            onClick={() => {
                              setIsEdit(isEdit === null ? index : null)
                              setEditedAmt(data?.value)
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
            <span>Total Deal Value is: {proposedDealAmount}</span>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default CrossSellModal
