import React, { Fragment } from 'react'
import PageHeader from './utils/PageHeader'
import Select from 'react-select'
import { CardBody, Col, Row, Table } from 'reactstrap'
const ViewExpense = () => {
  return (
    <Fragment>
      <PageHeader
        pageName='VIEW EXPENSE'
        breadcrump1='Home'
        breadcrump2='View Expense'
        link='/dashboard'
      />
      <div className='main lg:p-6 xl:p-6 2xl:p-6 sm:p-3 md:p-3 '>
        <CardBody className='bg-white p-3'>
          <form>
            <Row>
              <Col lg={3} md={3} sm={12} xs={12}>
                <div className='mb-2'>
                  <Select placeholder='Select Lead' />
                </div>
              </Col>
              <Col lg={3} md={3} sm={12} xs={12}>
                <div className='mb-2'>
                  <Select placeholder='Select Lead Owner' />
                </div>
              </Col>
              <Col lg={3} md={3} sm={12} xs={12}>
                <div className='mb-2'>
                  <select className='form-select'>
                    <option>Select Month</option>
                    <option>January</option>
                    <option>February</option>
                    <option>March</option>
                    <option>April</option>
                    <option>May</option>
                    <option>June</option>
                    <option>July</option>
                    <option>August</option>
                    <option>September</option>
                    <option>October</option>
                    <option>November</option>
                    <option>December</option>
                  </select>
                </div>
              </Col>
            </Row>
          </form>
        </CardBody>
        <CardBody>
          <div className='table-responsive sm:overflow-auto sm:max-h-[400px] w-full  mb-3 '>
            <Table hover className='align-middle table-nowrap'>
              <thead className='table-light'>
                <tr>
                  <th>Lead Name</th>
                  <th>Owner</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Invoice</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Demo Lead</td>
                  <td>Arnab</td>
                  <td>Travel</td>
                  <td>205-01-10</td>
                  <td>Rs 3000</td>
                  <td></td>
                  <td>Approve</td>
                </tr>
                <tr>
                  <td>Demo Lead</td>
                  <td>Arnab</td>
                  <td>Travel</td>
                  <td>205-01-10</td>
                  <td>Rs 3000</td>
                  <td></td>
                  <td>Approve</td>
                </tr>
                <tr>
                  <td>Demo Lead</td>
                  <td>Arnab</td>
                  <td>Travel</td>
                  <td>205-01-10</td>
                  <td>Rs 3000</td>
                  <td></td>
                  <td>Approve</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </CardBody>
        {/* <CardBody className='float-right'>
            <nav className='ml-5' aria-label='...'>
              <ul className='pagination'>
                <li className='page-item disabled'>
                  <Link className='page-link text-primary' to='#' tabIndex='-1'>
                    Previous
                  </Link>
                </li>
                {[...Array(totalPages)].map((_, index) => {
                  return (
                    <li className='page-item' key={index}>
                      <Link
                        onClick={() => setPageNumber(index + 1)}
                        className='page-link text-primary'
                        to='#'
                      >
                        {index + 1}
                      </Link>
                    </li>
                  )
                })}
                <li className='page-item'>
                  <Link className='page-link text-primary' to='#'>
                    Next
                  </Link>
                </li>
              </ul>
            </nav>
          </CardBody> */}
      </div>
    </Fragment>
  )
}

export default ViewExpense
