import React, { Fragment, useEffect, useState } from 'react'
import {
  Col,
  Row,
  Table,
  CardBody,
  Label,
  Input,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Button
} from 'reactstrap'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { CSVLink } from 'react-csv'
import { AddEmpModal } from './Modals/AddEmpModal'
import EditEmpModal from './Modals/EditEmpModal'
import { API_URL } from './utils/Constants/URLS'
import '../css/style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import {
  faDownload,
  faList,
  faPenToSquare,
  faUpload
} from '@fortawesome/free-solid-svg-icons'
import PageHeader from './utils/PageHeader'
import PageLoader from './utils/PageLoader'
const OrgContact = () => {
  const [orgContact, setOrgContact] = useState([])
  const [showFilterCanvas, setShowFilterCanvas] = useState(false)
  const [businessLineList, setBusinessLineList] = useState([])
  const [businessLineId, setBusinessLineId] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const [sortByValue, setSortByValue] = useState(null)
  const [totalPages, setTotalPages] = useState(1)
  const [finalSearchValue] = useDebounce(searchValue, 1000)
  const [modalData, setModalData] = useState(null)
  const [bulkUploadModal, setBulkUploadModal] = useState(false)
  const [admin, setAdmin] = useState('')
  const [manager, setManager] = useState(null)
  const [exe, setExe] = useState(null)
  const [employeeFile, setEmployeeFile] = useState(null)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const [pageLoader, setPageLoader] = useState(false)
  const token = localStorage.getItem('token')
  const userDetails = useSelector(store => store.user)
  const [role, setRole] = useState(
    userDetails.role === 'manager' ? 'executive' : 'manager'
  )

  useEffect(() => {
    getOrgContactData(pageNumber)
  }, [pageNumber, finalSearchValue, sortByValue, businessLineId])

  const getOrgContactData = async page_number => {
    console.log(businessLineId.join(','))
    let fileredRole = ''
    if (admin === '' && manager === '' && exe === '') {
      fileredRole = ''
    } else {
      fileredRole = `role=${admin},${manager},${exe}`
    }
    const url = `${API_URL}users/org-contacts?page=${page_number}&limit=100&search=${finalSearchValue}&
    sortBy=${sortByValue}&businessLine=${businessLineId.join(',')}`
    const data = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    const result = await data.json()
    setOrgContact(result.data.docs)
    setTotalPages(result.data.totalPages)
  }

  const invokeEditEmpModal = (
    id,
    name,
    phone,
    phoneOptional,
    email,
    role,
    businessArea,
    businessLine
  ) => {
    setModalData(null) // Reset modal data
    setTimeout(() => {
      setModalData({
        id,
        name,
        phone,
        phoneOptional,
        email,
        role,
        businessArea,
        businessLine
      })
    }, 0) // Slight delay to ensure React registers the state change
  }

  const closeFilterCanvas = () => {
    setShowFilterCanvas(false)
  }

  const clearFilter = () => {
    setBusinessLineId([])
    setSearchValue('')
  }

  const handleAddBulkEmployee = async e => {
    e.preventDefault()
    setPageLoader(true)
    const formData = new FormData()
    formData.append('csv', employeeFile)
    try {
      const res = await fetch(`${API_URL}users/${role}/bulk`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: formData,
        redirect: 'follow'
      })
      const result = await res.json()
      console.log(result.data.success.length)
      if (result?.error === false) {
        if (result?.data?.errors?.length === 0) {
          setBulkUploadModal(false)
          setShowSuccessModal(true)
        } else {
          setBulkUploadModal(false)
          setShowDeclineModal(true)
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      setPageLoader(false)
    }
  }

  const handleFilteredBusinessLine = event => {
    const { value, checked } = event.target

    if (checked) {
      // Add the value to the state if the checkbox is checked
      setBusinessLineId(prevValues => [...prevValues, value])
    } else {
      // Remove the value from the state if the checkbox is unchecked
      setBusinessLineId(prevValues => prevValues.filter(v => v !== value))
    }
  }

  useEffect(() => {
    const fetchBusinessLines = async () => {
      const url = `${API_URL}business-lines?pagination=false`
      const data = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      const result = await data.json()
      setBusinessLineList(
        result.data.docs.map(item => ({
          value: item.id,
          label: item.name
        }))
      )
    }

    fetchBusinessLines()
  }, [token])

  return (
    <Fragment>
      <div className='w-100'>
        <PageHeader
          pageName='ORGANISATION CONTACT'
          breadcrump1='Home'
          breadcrump2='Organisation Contact'
          link='/dashboard'
        />
        <div className='main lg:p-6 xl:p-6 2xl:p-6 sm:p-3 md:p-3 '>
          <CardBody className='bg-white p-3'>
            <form>
              <Row>
                <Col lg={3} md={3} sm={12} xs={12}>
                  <div className='mb-2'>
                    <input
                      className='form-control'
                      type='text'
                      onChange={e => setSearchValue(e.target.value)}
                      placeholder='Search Here'
                    />
                  </div>
                </Col>
                {userDetails.role !== 'executive' ? (
                  <Col lg={9} md={9} sm={12} xs={12} className='flex-wrap'>
                    <div className='lg:float-right xl:float-right 2xl:float-right flex'>
                      <div>
                        <button
                          type='button'
                          onClick={() => setShowFilterCanvas(true)}
                          className='btn filter-btn mr-2 text-white'
                        >
                          <FontAwesomeIcon className='mr-3' icon={faList} />{' '}
                          Filters
                        </button>
                      </div>
                      <div className='mr-2'>
                        <AddEmpModal />
                      </div>
                      <div>
                        <button
                          type='button'
                          className='btn download-btn mr-2 '
                        >
                          <CSVLink
                            className='mt-3 bg-transparent download-btn'
                            filename='organisation_contact.xlsx'
                            data={orgContact.map(item => ({
                              Name: item?.name,
                              Email: item?.email,
                              Role: item?.role,
                              'Business Line': item?.businessLine?.name,
                              'Business Area': item?.businessArea,
                              'Phone Number': item?.phoneNumber,
                              'Alternate Phone Number':
                                item?.phoneNumberOptional
                            }))}
                          >
                            <FontAwesomeIcon icon={faDownload} />
                          </CSVLink>
                        </button>
                      </div>
                      <div>
                        <button
                          type='button'
                          className='btn download-btn mr-2 '
                          onClick={() => setBulkUploadModal(true)}
                        >
                          <FontAwesomeIcon icon={faUpload} />
                        </button>
                      </div>
                    </div>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </form>
          </CardBody>
          <CardBody>
            <div className='table-responsive sm:overflow-auto sm:max-h-[400px] w-full  mb-3 '>
              <Table hover className='align-middle table-nowrap'>
                <thead className='table-light'>
                  <tr>
                    <th className='sticky left-0 top-0 px-4 z-20'>
                      <div className='flex items-center'>
                        Name
                        <svg
                          onClick={() =>
                            sortByValue === 'name'
                              ? setSortByValue(null)
                              : setSortByValue('name')
                          }
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th className='sticky top-0 z-10'>
                      <div className='flex items-center'>
                        Email
                        <svg
                          onClick={() =>
                            sortByValue === 'email'
                              ? setSortByValue(null)
                              : setSortByValue('email')
                          }
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th className='sticky top-0 z-10'>
                      <div className='flex items-center'>
                        Phone No
                        <svg
                          onClick={() =>
                            sortByValue === 'phoneNumber'
                              ? setSortByValue(null)
                              : setSortByValue('phoneNumber')
                          }
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th className='sticky top-0 z-10'>
                      <div className='flex items-center'>
                        Role
                        <svg
                          onClick={() =>
                            sortByValue === 'role'
                              ? setSortByValue(null)
                              : setSortByValue('role')
                          }
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th className='sticky top-0 z-10'>
                      <div className='flex items-center'>
                        Business Area
                        <svg
                          onClick={() =>
                            sortByValue === 'role'
                              ? setSortByValue(null)
                              : setSortByValue('role')
                          }
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    <th className='sticky top-0 z-10'>
                      <div className='flex items-center'>
                        Business Line
                        <svg
                          onClick={() =>
                            sortByValue === 'name'
                              ? setSortByValue(null)
                              : setSortByValue('name')
                          }
                          className='w-3 h-3 ms-1.5'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z' />
                        </svg>
                      </div>
                    </th>
                    {userDetails.role !== 'executive' ? (
                      <th className='sticky top-0 z-10'>Action</th>
                    ) : (
                      ''
                    )}
                  </tr>
                </thead>

                <tbody>
                  {orgContact.map((empDetails, index) => {
                    return (
                      <tr key={index}>
                        <td className='sticky left-0 z-10 bg-white px-4 py-2 border-b'>
                          {empDetails?.name}
                        </td>
                        <td>
                          <Link
                            className='text-dark no-underline'
                            to={`mailto:${empDetails?.email}`}
                          >
                            {empDetails?.email}
                          </Link>
                        </td>
                        <td>
                          <Link
                            className='text-dark no-underline'
                            to={`tel:${empDetails?.phoneNumber}`}
                          >
                            {empDetails?.phoneNumber}
                          </Link>
                        </td>
                        <td>
                          {empDetails?.role.charAt(0)?.toUpperCase() +
                            empDetails?.role?.slice(1)}
                        </td>
                        <td>
                          {empDetails?.businessArea?.map((data, index) => {
                            return (
                              <span
                                className='p-1 ms-2 bg-blue-100 text-blue-600 text-sm'
                                key={index}
                              >
                                {data}
                              </span>
                            )
                          })}
                        </td>
                        <td>
                          {empDetails?.businessLine &&
                          empDetails?.businessLine.name
                            ? empDetails?.businessLine?.name
                            : ''}
                        </td>
                        {userDetails.role !== 'executive' ? (
                          <td>
                            <FontAwesomeIcon
                              onClick={() =>
                                invokeEditEmpModal(
                                  empDetails?.id,
                                  empDetails?.name,
                                  empDetails?.phoneNumber,
                                  empDetails?.phoneNumberOptional,
                                  empDetails?.email,
                                  empDetails?.role,
                                  empDetails?.businessArea,
                                  empDetails?.businessLine
                                )
                              }
                              className='text-warning'
                              icon={faPenToSquare}
                            />
                          </td>
                        ) : (
                          ''
                        )}
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
          <CardBody className='float-right'>
            <nav className='ml-5' aria-label='...'>
              <ul className='pagination'>
                <li className='page-item disabled'>
                  <Link className='page-link text-primary' to='#' tabIndex='-1'>
                    Previous
                  </Link>
                </li>
                {[...Array(totalPages)].map((_, index) => {
                  return (
                    <li className='page-item' key={index}>
                      <Link
                        onClick={() => setPageNumber(index + 1)}
                        className='page-link text-primary'
                        to='#'
                      >
                        {index + 1}
                      </Link>
                    </li>
                  )
                })}
                <li className='page-item'>
                  <Link className='page-link text-primary' to='#'>
                    Next
                  </Link>
                </li>
              </ul>
            </nav>
          </CardBody>
        </div>
        {modalData && (
          <EditEmpModal
            id={modalData?.id}
            show_modal={true}
            userName={modalData?.name}
            userEmail={modalData?.email}
            userPhone={modalData?.phone}
            userPhoneOptional={modalData?.phoneOptional}
            userRole={modalData?.role}
            business_area={modalData?.businessArea || ''}
            business_line={[modalData?.businessLine || '']}
            business_line_id={modalData?.businessLine?.id}
          />
        )}
      </div>
      <Modal show={showSuccessModal} centered>
        <Modal.Body>
          <div className='my-8 text-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='w-14 shrink-0 fill-green-500 inline'
              viewBox='0 0 512 512'
            >
              <path
                d='M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z'
                data-original='#000000'
              />
              <path
                d='M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z'
                data-original='#000000'
              />
            </svg>
            <h4 className='text-xl text-gray-800 font-semibold mt-4'>
              Job Done
            </h4>
            <p className='text-sm text-gray-500 leading-relaxed mt-4'>
              Employees Added Successfully!
            </p>
          </div>

          <button
            type='button'
            onClick={() => setShowSuccessModal(false)}
            className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-green-500 hover:bg-green-700'
          >
            Okay
          </button>
        </Modal.Body>
      </Modal>
      <Modal show={showDeclineModal} centered>
        <Modal.Body>
          <div className='my-8 text-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='w-14 fill-red-500 inline'
              viewBox='0 0 286.054 286.054'
            >
              <path
                fill='#e2574c'
                d='M143.027 0C64.04 0 0 64.04 0 143.027c0 78.996 64.04 143.027 143.027 143.027 78.996 0 143.027-64.022 143.027-143.027C286.054 64.04 222.022 0 143.027 0zm0 259.236c-64.183 0-116.209-52.026-116.209-116.209S78.844 26.818 143.027 26.818s116.209 52.026 116.209 116.209-52.026 116.209-116.209 116.209zm.009-196.51c-10.244 0-17.995 5.346-17.995 13.981v79.201c0 8.644 7.75 13.972 17.995 13.972 9.994 0 17.995-5.551 17.995-13.972V76.707c-.001-8.43-8.001-13.981-17.995-13.981zm0 124.997c-9.842 0-17.852 8.01-17.852 17.86 0 9.833 8.01 17.843 17.852 17.843s17.843-8.01 17.843-17.843c-.001-9.851-8.001-17.86-17.843-17.86z'
                data-original='#e2574c'
              ></path>
            </svg>
            <h4 className='text-xl text-gray-800 font-semibold mt-4'>Sorry!</h4>
            <p className='text-sm text-gray-500 leading-relaxed mt-4'>
              Something Went Wrong
            </p>
          </div>

          <button
            type='button'
            onClick={() => setShowDeclineModal(false)}
            className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-red-500 hover:bg-red-700'
          >
            Okay
          </button>
        </Modal.Body>
      </Modal>
      <Modal
        size='lg'
        show={bulkUploadModal}
        centered
        onHide={() => setBulkUploadModal(false)}
      >
        <Modal.Header className='bg-indigo-100' closeButton>
          <Modal.Title>Add Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {pageLoader && <PageLoader />}
          <Row>
            <p>
              <strong>Steps to Add Employee</strong>
            </p>
            <p>
              <ol>
                <li>Select The Respective Role.</li>
                <li>Upload the respective CSV file.</li>
                <li>
                  Click the Add Button to add the employees to the CRM
                  application.
                </li>
              </ol>
            </p>
          </Row>
          <Row>
            <Col md={8} sm={12}>
              <select
                className='form-select'
                onChange={e => setRole(e.target.value)}
              >
                {userDetails.role === 'manager' ? (
                  <option value='executive'>Executive</option>
                ) : (
                  <Fragment>
                    <option value='manager'>Manager</option>
                    <option value='executive'>Executive</option>
                  </Fragment>
                )}
              </select>
            </Col>
            <Col md={2} sm={12}>
              <input
                type='file'
                id='formFile'
                onChange={e => setEmployeeFile(e.target.files[0])}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='button'
            className='btn add-btn text-white'
            onClick={handleAddBulkEmployee}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        direction='end'
        isOpen={showFilterCanvas}
        id='offcanvasExample'
        toggle={closeFilterCanvas}
      >
        <OffcanvasHeader className='bg-light' toggle={closeFilterCanvas}>
          Contacts Fliters
        </OffcanvasHeader>
        <form
          action=''
          className='d-flex flex-column justify-content-end h-100'
        >
          <OffcanvasBody>
            <div className='mb-2'>
              <Label
                htmlFor='datepicker-range'
                className='form-label text-muted text-uppercase fw-semibold mb-3'
                onClick={clearFilter}
              >
                <Link className='text-gray-700 no-underline hover:text-dark'>
                  Clear Filters
                </Link>
              </Label>
            </div>
            <div className='mb-4'>
              <Label
                htmlFor='datepicker-range'
                className='form-label text-muted text-uppercase fw-semibold mb-3'
              >
                Role
              </Label>
              <Row>
                <Col md={6} lg={6} sm={6}>
                  <div className='form-check'>
                    <Input
                      onChange={() => setSearchValue('Admin')}
                      type='radio'
                      name='radio1'
                    />
                    <Label
                      className='form-check-label'
                      htmlFor='inlineCheckbox2'
                    >
                      Admin
                    </Label>
                  </div>
                </Col>
                <Col md={6} lg={6} sm={6}>
                  <div className='form-check'>
                    <Input
                      onChange={() => setSearchValue('Manager')}
                      name='radio1'
                      type='radio'
                    />
                    <Label
                      className='form-check-label'
                      htmlFor='inlineCheckbox2'
                    >
                      Manager
                    </Label>
                  </div>
                </Col>
                <Col md={6} lg={6} sm={6}>
                  <div className='form-check'>
                    <Input
                      onChange={() => setSearchValue('Executive')}
                      name='radio1'
                      type='radio'
                    />
                    <Label
                      className='form-check-label'
                      htmlFor='inlineCheckbox2'
                    >
                      Executive
                    </Label>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Label
                htmlFor='leads-tags'
                className='form-label text-muted text-uppercase fw-semibold mb-3'
              >
                Business Lines
              </Label>
              <Row className='g-3'>
                {businessLineList.map((data, index) => {
                  return (
                    <Col lg={6} key={index}>
                      <div className='form-check'>
                        <Input
                          className='form-check-input'
                          name='checkbox1'
                          type='checkbox'
                          value={data?.value}
                          onChange={handleFilteredBusinessLine}
                        />
                        <Label className='form-check-label' htmlFor='marketing'>
                          {data?.label}
                        </Label>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </div>
          </OffcanvasBody>
        </form>
      </Offcanvas>
    </Fragment>
  )
}

export default OrgContact
