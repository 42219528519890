import React, { Fragment } from 'react'
import ReactApexChart from 'react-apexcharts'
import { CardBody, CardHeader, Card } from 'reactstrap';

const DealVelocity = () => {
    const series = [{
        name: "Desktops",
        data: [10, 41, 35, 51]
    }];
    var options = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        markers: {
            size: 4,
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        colors: ['#2E93fA'],
        title: {
            align: 'left',
            style: {
                fontWeight: 500,
            },
        },
        xaxis: {
            labels: {
                rotate: -45
            },
            title: {
                text: "Business Line"
            },
            categories: ['THER', 'FW', 'DAN', 'GRUN'],

        },
        yaxis: {
            title: {
                text: "Closing Time(Days)"
            }
        }
    };

    return (
        <Fragment>
            <Card>
                <CardHeader>Deal Velocity</CardHeader>
                <CardBody>
                    <ReactApexChart
                        dir="ltr"
                        options={options}
                        series={series}
                        type="line"
                        height="350"
                        className="apex-charts"
                    />
                </CardBody>
            </Card>

        </Fragment>
    )
}

export default DealVelocity