import React, { Fragment, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import 'primereact/resources/themes/lara-light-cyan/theme.css'
import { Toast } from 'primereact/toast'
import Modal from 'react-bootstrap/Modal'
import { API_URL } from '../../utils/Constants/URLS'
import TerminateConfirmationModal from '../../Modals/TerminateConfirmationModal'
const BuyingPlatform = () => {
  const token = localStorage.getItem('token')
  const navigate = useNavigate()
  const { id } = useParams()
  const toast = useRef(null)
  const [modalMessage, setModalMessage] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const [stage, setStage] = useState('')
  const [lostOrWonAgainst, setAgainst] = useState('')
  const [closingReason, setClosingReason] = useState('')

  const handleCloseModal = () => {
    setShowSuccessModal(false)
    setShowDeclineModal(false)
    navigate('/view_leads')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (stage === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select Lead Stage',
        life: 3000
      })
    } else if (stage === 'lost' && lostOrWonAgainst === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter Lost or Won against Whom',
        life: 3000
      })
    } else if ((stage === 'lost' || stage === 'drop') && closingReason === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter Reason for Lost/Drop',
        life: 3000
      })
    } else {
      try {
        const res = await fetch(`${API_URL}leads/${id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            buyingPlatform: {
              status: 'done',
              stage,
              lostOrWonAgainst,
              closingReason,
            }
          })
        })
        const response = await res.json()
        if (response.error === false) {
          setModalMessage('Buying Platform Updated Succesfully')
          setShowSuccessModal(true)
        } else {
          setModalMessage('Something Went Wrong')
          setShowDeclineModal(true)
        }
      } catch (e) {
        setModalMessage('Oops! Error while adding data')
        setShowDeclineModal(true)
      }
    }
  }
  return (
    <Fragment>
      <div>
        <form className='row g-3 needs-validation' noValidate>
          <div className='row mt-2'>
            <div className='col-md-6'>
              <label for='name' className='form-label'>
                Stage Update <span className='text-danger'>*</span>
              </label>
              <select
                value={stage}
                onChange={e => setStage(e.target.value)}
                className='form-select'
              >
                <option>Select</option>
                <option value='WON'>Closed Won</option>
                <option value='LOST'>Closed Lost</option>
                <option value='DROP'>Drop</option>
              </select>
            </div>
            <div className='col-md-6'>
              <label htmlFor='name' className='form-label'>
                {stage === 'WON' ? (
                  'Won against whom?'
                ) : stage === 'WON' ? (
                  <>
                    Lost against whom? <span className='text-danger'>*</span>
                  </>
                ) : (
                  'Drop against whom?'
                )}
              </label>

              <input
                value={lostOrWonAgainst}
                onChange={e => setAgainst(e.target.value)}
                type='text'
                className='form-control'
              />
            </div>
          </div>
          {stage === 'LOST' || stage === 'DROP' ? (
            <div className='row mt-2'>
              <div className='col-md-12'>
                <label for='name' className='form-label'>
                  Reason for Lost/Drop <span className='text-danger'>*</span>
                </label>
                <textarea
                  value={closingReason}
                  onChange={e => setClosingReason(e.target.value)}
                  type='text'
                  className='form-control'
                />
              </div>
            </div>
          ) : (
            ''
          )}
          <div className='col-md-12 d-flex justify-content-between'>
            <TerminateConfirmationModal leadId={id} />
            <button
              onClick={handleSubmit}
              className='btn add-btn text-white'
              type='submit'
            >
              Submit
            </button>
          </div>
        </form>
        <Modal show={showSuccessModal} centered>
          <Modal.Body>
            <div class='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 shrink-0 fill-green-500 inline'
                viewBox='0 0 512 512'
              >
                <path
                  d='M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z'
                  data-original='#000000'
                />
                <path
                  d='M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z'
                  data-original='#000000'
                />
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                Good Job
              </h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                {modalMessage}!
              </p>
            </div>

            <button
              type='button'
              onClick={handleCloseModal}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-green-500 hover:bg-green-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
        <Modal show={showDeclineModal} centered>
          <Modal.Body>
            <div className='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 fill-red-500 inline'
                viewBox='0 0 286.054 286.054'
              >
                <path
                  fill='#e2574c'
                  d='M143.027 0C64.04 0 0 64.04 0 143.027c0 78.996 64.04 143.027 143.027 143.027 78.996 0 143.027-64.022 143.027-143.027C286.054 64.04 222.022 0 143.027 0zm0 259.236c-64.183 0-116.209-52.026-116.209-116.209S78.844 26.818 143.027 26.818s116.209 52.026 116.209 116.209-52.026 116.209-116.209 116.209zm.009-196.51c-10.244 0-17.995 5.346-17.995 13.981v79.201c0 8.644 7.75 13.972 17.995 13.972 9.994 0 17.995-5.551 17.995-13.972V76.707c-.001-8.43-8.001-13.981-17.995-13.981zm0 124.997c-9.842 0-17.852 8.01-17.852 17.86 0 9.833 8.01 17.843 17.852 17.843s17.843-8.01 17.843-17.843c-.001-9.851-8.001-17.86-17.843-17.86z'
                  data-original='#e2574c'
                ></path>
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                Error Occured
              </h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                {modalMessage}!
              </p>
            </div>

            <button
              type='button'
              onClick={handleCloseModal}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-red-500 hover:bg-red-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
        <Toast ref={toast} />
      </div>
    </Fragment>
  )
}

export default BuyingPlatform
