import React, { Fragment, useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  Table,
  TabPane
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons'
import '../css/common.css'
import '../css/ClientProfile.css'
import { Link, useParams } from 'react-router-dom'
import { API_URL } from './utils/Constants/URLS'
import PageHeader from './utils/PageHeader'
import EditEnquiryDetails from './Forms/FormComponents/EditLead/EditEnquiryDetails'
import EditProdReq from './Forms/FormComponents/EditLead/EditProductReq'
import EditMarketingPlatform from './Forms/FormComponents/EditLead/EditMarketingPlatform'
import EditWorkingPlatform from './Forms/FormComponents/EditLead/EditWorkingPlatform'
import EditBuyingPlatform from './Forms/FormComponents/EditLead/EditBuyingPlatform'
import { useSelector } from 'react-redux'

const LeadProfile = () => {
  const [leadDetails, setLeadDetails] = useState('')
  const [editEnquiryDetails, setEditEnquiryDetails] = useState(false)
  const [editProductRequirement, setEditProductRequirement] = useState(false)
  const [editMarketingPlatform, setEditMarkeingPlatform] = useState(false)
  const [editWorkingPlatform, setEditWorkingPlatform] = useState(false)
  const [editBuyingPlatform, setEditBuyingPlatform] = useState(false)
  const [crossSellData, setCrossSellData] = useState([])
  const [activeBtn, setActiveBtn] = useState('Capture Details')
  const token = localStorage.getItem('token')
  const { leadId } = useParams()
  const userDetails = useSelector(store => store.user)

  useEffect(() => {
    const getLeadDetails = async () => {
      const url = `${API_URL}leads/${leadId}`

      const data = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      const result = await data.json()
      setLeadDetails(result.data)
    }
    getLeadDetails()
    getCrossSellData()
  }, [leadId, token])

  const getCrossSellData = async () => {
    const res = await fetch(
      `${API_URL}cross-sells?pagination=false&leadId=${leadId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/Json',
          Authorization: `Bearer ${token}`
        }
      }
    )
    const result = await res.json()
    setCrossSellData(result?.data?.docs)
  }

  const handleBtnClick = btn => {
    setActiveBtn(btn)
  }

  return (
    <Fragment>
      <PageHeader
        pageName='LEAD DETAILS'
        breadcrump1='Home'
        breadcrump2='Lead Details'
        link='/dashboard'
      />
      <div className='pl-5 pr-3'>
        <Container fluid>
          <Row className='mt-4'>
            <Col md={3}>
              <Card className='p-2'>
                <CardBody className='border-bottom'>
                  <h4 className='text-slate-600'>
                    {leadDetails?.leadJourneyName}
                  </h4>
                  <p className='mt-3 '>
                    Amount: Rs{' '}
                    {leadDetails?.productRequirements?.proposedDealAmount}
                  </p>
                  <p className='mt-3'>
                    Close Date:{' '}
                    {leadDetails?.enquiryDetails?.tentativeClosureDate?.substring(
                      0,
                      10
                    )}
                  </p>
                </CardBody>
                <CardBody className='border-bottom'>
                  <h5 className='text-slate-600'>Lead Summary</h5>
                  <span>
                    <label className='form-label'>
                      <strong className='text-slate-600'>Owner</strong>
                    </label>
                    <p>{leadDetails?.leadOwner?.name}</p>
                  </span>
                  <span>
                    <label className='form-label'>
                      <strong className='text-slate-600'>Company</strong>
                    </label>
                    <p>{leadDetails?.client?.companyName}</p>
                  </span>
                  <span>
                    <label className='form-label'>
                      <strong className='text-slate-600'>Type of Sale</strong>
                    </label>
                    <p>{leadDetails?.productRequirements?.typeOfSale}</p>
                  </span>
                  <span>
                    <label className='form-label'>
                      <strong className='text-slate-600'>Nature of Sale</strong>
                    </label>
                    <p>{leadDetails?.productRequirements?.natureOfSale}</p>
                  </span>
                  <span>
                    <label className='form-label'>
                      <strong className='text-slate-600'>Business Line</strong>
                    </label>
                    <p>{leadDetails?.businessLine?.name}</p>
                  </span>
                </CardBody>
              </Card>
            </Col>
            <Col md={9}>
              <Card>
                <CardBody>
                  <div className='d-flex'>
                    <Link
                      className={`client-activities ${
                        activeBtn === 'Capture Details'
                          ? 'active no-underline'
                          : 'text-dark no-underline'
                      } p-1 col-md-3 text-center`}
                    >
                      <span onClick={() => handleBtnClick('Capture Details')}>
                        Known Universe
                      </span>
                    </Link>
                    <Link
                      className={`client-activities ${
                        activeBtn === 'Update Platform'
                          ? 'active no-underline'
                          : 'text-dark no-underline'
                      } p-1 col-md-3 text-center`}
                    >
                      <span onClick={() => handleBtnClick('Update Platform')}>
                        Update Platform
                      </span>
                    </Link>
                    <Link
                      className={`client-activities ${
                        activeBtn === 'Agreement'
                          ? 'active no-underline'
                          : 'text-dark no-underline'
                      } p-1 col-md-3 text-center`}
                    >
                      <span onClick={() => handleBtnClick('Agreement')}>
                        Agreement
                      </span>
                    </Link>

                    <Link
                      className={`client-activities ${
                        activeBtn === 'cross-sell'
                          ? 'active no-underline'
                          : 'text-dark no-underline'
                      } p-1 col-md-3 text-center`}
                    >
                      <span onClick={() => handleBtnClick('cross-sell')}>
                        Cross Sell
                      </span>
                    </Link>
                  </div>
                </CardBody>
              </Card>
              <TabContent activeTab={activeBtn}>
                <TabPane tabId='Capture Details'>
                  <Card className='mt-3'>
                    <CardBody>
                      <Row>
                        <h4>Client Details</h4>
                        <hr></hr>
                        <Col md={4}>
                          <label className='form-label'>
                            <strong>Company Name</strong>
                          </label>
                          <p>{leadDetails?.client?.companyName || '-'}</p>
                        </Col>
                        <Col md={4}>
                          <label className='form-label'>
                            <strong>Type of Organisation</strong>
                          </label>
                          <p>{leadDetails?.client?.organizationType || '-'}</p>
                        </Col>
                        <Col md={4}>
                          {leadDetails?.client?.isGSTRegistered === true ? (
                            <Fragment>
                              <label className='form-label'>
                                <strong>GSTIN</strong>
                              </label>
                              <p>{leadDetails?.client?.gstNumber || '-'}</p>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <label className='form-label'>
                                <strong>PAN</strong>
                              </label>
                              <p>{leadDetails?.client?.panNumber || '-'}</p>
                            </Fragment>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <h4>
                          Product Requirements{' '}
                          {leadDetails.leadStatus === 'pending' &&
                          leadDetails.productRequirements.status === 'done' &&
                          userDetails?.role !== 'super_admin' &&
                          userDetails?.role !== 'admin' ? (
                            editProductRequirement === true ? (
                              <FontAwesomeIcon
                                className='text-danger ms-2 mb-1 text-sm'
                                onClick={() => setEditProductRequirement(false)}
                                icon={faXmark}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className='text-warning ms-2 mb-1 text-sm'
                                onClick={() => setEditProductRequirement(true)}
                                icon={faPenToSquare}
                              />
                            )
                          ) : (
                            ''
                          )}
                        </h4>
                        <hr></hr>
                        {editProductRequirement === true ? (
                          <EditProdReq
                            id={leadDetails.id}
                            info={leadDetails.productRequirements}
                          />
                        ) : (
                          <Fragment>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Type of Sale</strong>
                              </label>
                              <p>
                                {leadDetails?.productRequirements?.typeOfSale ||
                                  '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Nature of Sale</strong>
                              </label>
                              <p>
                                {leadDetails?.productRequirements
                                  ?.natureOfSale || '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Industry</strong>
                              </label>
                              <p>
                                {leadDetails?.productRequirements?.industry ||
                                  '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Proposed Deal Amount</strong>
                              </label>
                              <p>
                                Rs{' '}
                                {
                                  leadDetails?.productRequirements
                                    ?.proposedDealAmount
                                }
                              </p>
                            </Col>

                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Enquiry Details</strong>
                              </label>
                              <p>
                                {leadDetails?.productRequirements
                                  ?.enquiryDetails || '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Lead Source</strong>
                              </label>
                              <p>
                                {leadDetails?.productRequirements?.leadSource ||
                                  '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Description</strong>
                              </label>
                              <p>
                                {leadDetails?.productRequirements
                                  ?.description || '-'}
                              </p>
                            </Col>
                          </Fragment>
                        )}
                      </Row>
                      <Row>
                        <h4>
                          Enquiry Details{' '}
                          {leadDetails.leadStatus === 'pending' &&
                          leadDetails.enquiryDetails.status === 'done' &&
                          userDetails?.role !== 'super_admin' &&
                          userDetails?.role !== 'admin' ? (
                            editEnquiryDetails === true ? (
                              <FontAwesomeIcon
                                className='text-danger ms-2 mb-1 text-sm'
                                onClick={() => setEditEnquiryDetails(false)}
                                icon={faXmark}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className='text-warning ms-2 mb-1 text-sm'
                                onClick={() => setEditEnquiryDetails(true)}
                                icon={faPenToSquare}
                              />
                            )
                          ) : (
                            ''
                          )}
                        </h4>
                        <hr></hr>
                        {editEnquiryDetails === true ? (
                          <EditEnquiryDetails
                            id={leadDetails?.id}
                            info={leadDetails?.enquiryDetails}
                          />
                        ) : (
                          <Fragment>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Project Type</strong>
                              </label>
                              <p>
                                {leadDetails?.enquiryDetails?.projectType ||
                                  '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Established Contact </strong>
                              </label>
                              <p>
                                {leadDetails?.enquiryDetails
                                  ?.contactEstablished === true
                                  ? 'Yes'
                                  : leadDetails?.enquiryDetails
                                      ?.contactEstablished === false
                                  ? 'No'
                                  : '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Information Received</strong>
                              </label>
                              <p>
                                {leadDetails?.enquiryDetails
                                  ?.informationReceived === true
                                  ? 'Yes'
                                  : leadDetails?.enquiryDetails
                                      ?.informationReceived === false
                                  ? 'No'
                                  : '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Further Information</strong>
                              </label>
                              <p>
                                {leadDetails?.enquiryDetails
                                  ?.furtherInformation || '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Identified User</strong>
                              </label>
                              <p>
                                {leadDetails?.enquiryDetails?.userIdentified ===
                                true
                                  ? 'Yes'
                                  : leadDetails?.enquiryDetails
                                      ?.userIdentified === false
                                  ? 'No'
                                  : '-'}
                              </p>
                            </Col>

                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Any Past Meeting</strong>
                              </label>
                              <p>
                                {leadDetails?.enquiryDetails?.anyPastMeeting ===
                                true
                                  ? 'Yes'
                                  : leadDetails?.enquiryDetails
                                      ?.anyPastMeeting === false
                                  ? 'No'
                                  : '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Meeting Date</strong>
                              </label>
                              <p>
                                {leadDetails?.enquiryDetails?.meetingDate?.substring(
                                  0,
                                  10
                                ) || '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Meeting Notes</strong>
                              </label>
                              <p>
                                {leadDetails?.enquiryDetails?.meetingNotes ||
                                  '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Tentative Closure Date</strong>
                              </label>
                              <p>
                                {leadDetails?.enquiryDetails?.tentativeClosureDate?.substring(
                                  0,
                                  10
                                ) || '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Need Identified</strong>
                              </label>
                              <p>
                                {leadDetails?.enquiryDetails?.needIdentified ===
                                true
                                  ? 'Yes'
                                  : leadDetails?.enquiryDetails
                                      ?.needIdentified === false
                                  ? 'No'
                                  : '-'}
                              </p>
                            </Col>
                          </Fragment>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </TabPane>
                <TabPane tabId='Update Platform'>
                  <Card className='mt-3'>
                    <CardBody>
                      <Row>
                        <h4>
                          Marketing Platform{' '}
                          {leadDetails?.leadStatus === 'pending' &&
                          leadDetails?.marketingPlatform?.status === 'done' &&
                          userDetails?.role !== 'super_admin' &&
                          userDetails?.role !== 'admin' ? (
                            editMarketingPlatform === true ? (
                              <FontAwesomeIcon
                                className='text-danger ms-2 mb-1 text-sm'
                                onClick={() => setEditMarkeingPlatform(false)}
                                icon={faXmark}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className='text-warning ms-2 mb-1 text-sm'
                                onClick={() => setEditMarkeingPlatform(true)}
                                icon={faPenToSquare}
                              />
                            )
                          ) : (
                            ''
                          )}
                        </h4>
                        <hr></hr>
                        {editMarketingPlatform === true ? (
                          <EditMarketingPlatform
                            id={leadDetails.id}
                            info={leadDetails.marketingPlatform}
                          />
                        ) : (
                          <Fragment>
                            <Col md={12}>
                              <label className='form-label'>
                                <strong>Identified Influencer</strong>
                              </label>
                              <p>
                                {leadDetails?.marketingPlatform
                                  ?.influencerIdentified === true
                                  ? 'Yes'
                                  : leadDetails?.marketingPlatform
                                      ?.influencerIdentified === false
                                  ? 'No'
                                  : '-'}
                              </p>
                            </Col>
                            {leadDetails?.marketingPlatform
                              ?.influencerIdentified === true ? (
                              <Fragment>
                                <Col md={3}>
                                  <label className='form-label'>
                                    <strong>Influencer Name</strong>
                                  </label>
                                  <p>
                                    {
                                      leadDetails?.marketingPlatform
                                        ?.contactPerson?.name
                                    }
                                  </p>
                                </Col>
                                <Col md={3}>
                                  <label className='form-label'>
                                    <strong>Influencer Phone</strong>
                                  </label>
                                  <p>
                                    {
                                      leadDetails?.marketingPlatform
                                        ?.contactPerson?.phoneNumber
                                    }
                                  </p>
                                </Col>
                                <Col md={3}>
                                  <label className='form-label'>
                                    <strong>Influencer Email</strong>
                                  </label>
                                  <p>
                                    {
                                      leadDetails?.marketingPlatform
                                        ?.contactPerson?.email
                                    }
                                  </p>
                                </Col>
                                <Col md={3}>
                                  <label className='form-label'>
                                    <strong>Influencer Designation</strong>
                                  </label>
                                  <p>
                                    {
                                      leadDetails?.marketingPlatform
                                        ?.contactPerson?.designation
                                    }
                                  </p>
                                </Col>
                              </Fragment>
                            ) : (
                              ''
                            )}

                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Equipment Confirmed</strong>
                              </label>
                              <p>
                                {leadDetails?.marketingPlatform
                                  ?.equipmentConfirmed === true
                                  ? 'Yes'
                                  : leadDetails?.marketingPlatform
                                      ?.equipmentConfirmed === false
                                  ? 'No'
                                  : '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Submitted 1st Offer</strong>
                              </label>
                              <p>
                                {leadDetails?.marketingPlatform
                                  ?.submittedFirstOffer === true
                                  ? 'Yes'
                                  : leadDetails?.marketingPlatform
                                      ?.submittedFirstOffer === true
                                  ? 'No'
                                  : '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Compititors</strong>
                              </label>
                              <p>
                                {leadDetails?.marketingPlatform?.competitors ||
                                  '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>
                                  Statutory Requirements Identified
                                </strong>
                              </label>
                              <p>
                                {leadDetails?.marketingPlatform
                                  ?.statutoryRequirementsIdentified === true
                                  ? 'Yes'
                                  : leadDetails?.marketingPlatform
                                      ?.statutoryRequirementsIdentified ===
                                    false
                                  ? 'No'
                                  : '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Any Past Meetings</strong>
                              </label>
                              <p>
                                {leadDetails?.marketingPlatform
                                  ?.anyPastMeeting === true
                                  ? 'Yes'
                                  : leadDetails?.marketingPlatform
                                      ?.anyPastMeeting === false
                                  ? 'No'
                                  : '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Meeting Date</strong>
                              </label>
                              <p>
                                {leadDetails?.marketingPlatform?.meetingDate?.substring(
                                  0,
                                  10
                                ) || '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Meeting Notes</strong>
                              </label>
                              <p>
                                {leadDetails?.marketingPlatform?.meetingNotes ||
                                  '-'}
                              </p>
                            </Col>
                          </Fragment>
                        )}
                      </Row>
                      <Row>
                        <h4>
                          Working Platform{' '}
                          {leadDetails.leadStatus === 'pending' &&
                          leadDetails.workingPlatform.status === 'done' &&
                          userDetails?.role !== 'super_admin' &&
                          userDetails?.role !== 'admin' ? (
                            editWorkingPlatform === true ? (
                              <FontAwesomeIcon
                                className='text-danger ms-2 mb-1 text-sm'
                                onClick={() => setEditWorkingPlatform(false)}
                                icon={faXmark}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className='text-warning ms-2 mb-1 text-sm'
                                onClick={() => setEditWorkingPlatform(true)}
                                icon={faPenToSquare}
                              />
                            )
                          ) : (
                            ''
                          )}
                        </h4>
                        <hr></hr>
                        {editWorkingPlatform === true ? (
                          <EditWorkingPlatform
                            id={leadDetails.id}
                            info={leadDetails.workingPlatform}
                          />
                        ) : (
                          <Fragment>
                            <Col md={12}>
                              <label className='form-label'>
                                <strong>
                                  Is End User Different For Customer
                                </strong>
                              </label>
                              <p>
                                {leadDetails?.workingPlatform
                                  ?.differentEndUser === true
                                  ? 'Yes'
                                  : leadDetails?.workingPlatform
                                      ?.differentEndUser === false
                                  ? 'No'
                                  : '-'}
                              </p>
                            </Col>
                            {leadDetails?.workingPlatform?.differentEndUser ===
                            true ? (
                              <Fragment>
                                <Col md={3}>
                                  <label className='form-label'>
                                    <strong>User Name</strong>
                                  </label>
                                  <p>
                                    {
                                      leadDetails?.workingPlatform
                                        ?.contactPerson?.name
                                    }
                                  </p>
                                </Col>
                                <Col md={3}>
                                  <label className='form-label'>
                                    <strong>User Email</strong>
                                  </label>
                                  <p>
                                    {
                                      leadDetails?.workingPlatform
                                        ?.contactPerson?.email
                                    }
                                  </p>
                                </Col>
                                <Col md={3}>
                                  <label className='form-label'>
                                    <strong>User Phone</strong>
                                  </label>
                                  <p>
                                    {
                                      leadDetails?.workingPlatform
                                        ?.contactPerson?.phoneNumber
                                    }
                                  </p>
                                </Col>
                                <Col md={3}>
                                  <label className='form-label'>
                                    <strong>User Designation</strong>
                                  </label>
                                  <p>
                                    {
                                      leadDetails?.workingPlatform
                                        ?.contactPerson?.designation
                                    }
                                  </p>
                                </Col>
                              </Fragment>
                            ) : (
                              ''
                            )}

                            <Col md={6}>
                              <label className='form-label'>
                                <strong>
                                  Technical and Commercial Discussion Completed?
                                </strong>
                              </label>
                              <p>
                                {leadDetails?.workingPlatform
                                  ?.technicalAndCommercialDiscussionCompleted ===
                                true
                                  ? 'Yes'
                                  : leadDetails?.workingPlatform
                                      ?.technicalAndCommercialDiscussionCompleted ===
                                    false
                                  ? 'No'
                                  : '-'}
                              </p>
                            </Col>
                            <Col md={6}>
                              <label className='form-label'>
                                <strong>Commercial Proposal Submitted?</strong>
                              </label>
                              <p>
                                {leadDetails?.workingPlatform
                                  ?.commercialProposalSubmitted === true
                                  ? 'Yes'
                                  : leadDetails?.workingPlatform
                                      ?.commercialProposalSubmitted === false
                                  ? 'No'
                                  : '-'}
                              </p>
                            </Col>
                            <Col md={6}>
                              <label className='form-label'>
                                <strong>
                                  First Round of Commercial negotiation started?
                                </strong>
                              </label>
                              <p>
                                {leadDetails?.workingPlatform
                                  ?.firstRoundCommercialNegotiationStarted ===
                                true
                                  ? 'Yes'
                                  : leadDetails?.workingPlatform
                                      ?.firstRoundCommercialNegotiationStarted ===
                                    false
                                  ? 'No'
                                  : '-'}
                              </p>
                            </Col>
                            <Col md={6}>
                              <label className='form-label'>
                                <strong>Approved Finance Description</strong>
                              </label>
                              <p>
                                {leadDetails?.workingPlatform
                                  ?.approvedFinanceDescription || '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Installation Visit Done?</strong>
                              </label>
                              <p>
                                {leadDetails?.workingPlatform
                                  ?.installationVisitDone === true
                                  ? 'Yes'
                                  : leadDetails?.workingPlatform
                                      ?.installationVisitDone === false
                                  ? 'No'
                                  : '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Date and Time of Visit?</strong>
                              </label>
                              <p>
                                {leadDetails?.workingPlatform?.dateOfVisit?.substring(
                                  0,
                                  10
                                ) || '-'}
                              </p>
                            </Col>
                            <Col md={3}>
                              <label className='form-label'>
                                <strong>Visit Note</strong>
                              </label>
                              <p>
                                {leadDetails?.workingPlatform?.visitNotes ||
                                  '-'}
                              </p>
                            </Col>
                          </Fragment>
                        )}
                      </Row>
                      <Row>
                        <h4>
                          Buying Platform{' '}
                          {leadDetails.leadStatus === 'pending' &&
                          leadDetails.buyingPlatform.status === 'done' &&
                          userDetails?.role !== 'super_admin' &&
                          userDetails?.role !== 'admin' ? (
                            editBuyingPlatform === true ? (
                              <FontAwesomeIcon
                                className='text-danger ms-2 mb-1 text-sm'
                                onClick={() => setEditBuyingPlatform(false)}
                                icon={faXmark}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className='text-warning ms-2 mb-1 text-sm'
                                onClick={() => setEditBuyingPlatform(true)}
                                icon={faPenToSquare}
                              />
                            )
                          ) : (
                            ''
                          )}
                        </h4>
                        <hr></hr>
                        {editBuyingPlatform === true ? (
                          <EditBuyingPlatform
                            id={leadDetails.id}
                            info={leadDetails.buyingPlatform}
                          />
                        ) : (
                          <Fragment>
                            <Col md={6}>
                              <label className='form-label'>
                                <strong>Stage Update</strong>
                              </label>
                              <p>{leadDetails?.buyingPlatform?.stage || '-'}</p>
                            </Col>
                            <Col md={6}>
                              <label className='form-label'>
                                <strong>Lost or Won against whom? </strong>
                              </label>
                              <p>
                                {leadDetails?.buyingPlatform
                                  ?.lostOrWonAgainst || '-'}
                              </p>
                            </Col>
                            <Col md={12}>
                              <label className='form-label'>
                                <strong>Reason for Lost/Drop</strong>
                              </label>
                              <p>
                                {leadDetails?.buyingPlatform?.closingReason ||
                                  '-'}
                              </p>
                            </Col>
                          </Fragment>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </TabPane>
                <TabPane tabId='Agreement'>
                  <Card className='mt-3'>
                    <CardBody>
                      <Row>
                        <h4>Agreement</h4>
                        <hr></hr>
                        <Col md={6}>
                          <label className='form-label'>
                            <strong>Stage Update</strong>
                          </label>
                          <p>{leadDetails?.agreement?.stage || '-'}</p>
                        </Col>
                        <Col md={6}>
                          <label className='form-label'>
                            <strong>Lost or Won against whom? </strong>
                          </label>
                          <p>
                            {leadDetails?.agreement?.lostOrWonAgainst || '-'}
                          </p>
                        </Col>
                        <Col md={12}>
                          <label className='form-label'>
                            <strong>Reason for Lost/Drop</strong>
                          </label>
                          <p>{leadDetails?.agreement?.closingReason || '-'}</p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </TabPane>
                <TabPane tabId='cross-sell'>
                  <Card className='mt-3'>
                    <CardBody>
                      <Table>
                        <thead>
                          <tr>
                            <th>Product Line</th>
                            <th>Model</th>
                            <th>Amount</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {crossSellData.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data.productLine.productLineName}</td>
                                <td>{data.model.modelName}</td>
                                <td>{data.value}</td>
                                <td>
                                  {data.status === 'added' ? (
                                    <span className='text-green-500'>IN</span>
                                  ) : (
                                    <span className='text-red-500'>OUT</span>
                                  )}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default LeadProfile
