import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardBody, Col, Row, TabContent, TabPane } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PageHeader from './utils/PageHeader'
import {
  faBriefcase,
  faEnvelope,
  faPhone
} from '@fortawesome/free-solid-svg-icons'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import '../css/ClientProfile.css'
import { Link, useParams } from 'react-router-dom'
import { API_URL } from './utils/Constants/URLS'
import { useSelector } from 'react-redux'

const ClientProfile = () => {
  const userDetails = useSelector(store => store.user)
  const [companyProfile, setCompanyProfile] = useState('')
  const [activeBtn, setActiveBtn] = useState('contact')
  const token = localStorage.getItem('token')
  const { resid } = useParams()

  useEffect(() => {
    getCompanyProfileDetails()
  }, [])

  const handleBtnClick = btn => {
    setActiveBtn(btn)
  }

  const getCompanyProfileDetails = async () => {
    const url = `${API_URL}clients/${resid}`

    const data = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })

    const result = await data.json()
    setCompanyProfile(result.data)
  }

  return (
    <Fragment>
      <PageHeader
        pageName='CLIENT PROFILE'
        breadcrump1='Home'
        breadcrump2='Client Profile'
        link='/dashboard'
      />
      <div className='pl-6 pr-4'>
        <Row className='mt-4 mr-2'>
          <Col md={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col className='d-flex p-3' md={4} lg={6} xl={6}>
                    <div className='ml-3'>
                      <h2>{companyProfile.companyName}</h2>
                      <h6 className='text-gray-500'>
                        <i class='bi bi-geo-alt'></i>
                        {companyProfile?.address?.[0]?.addressLine1}{' '}
                        {companyProfile?.address?.[0]?.city}{' '}
                        {companyProfile?.address?.[0]?.state}
                      </h6>
                      {companyProfile.isGSTRegistered === true ? (
                        <span className='text-gray-500'>
                          GSTIN: {companyProfile?.gstNumber}
                        </span>
                      ) : (
                        <span className='text-gray-500'>
                          PAN: {companyProfile?.panNumber}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col className='pt-5' md={8} lg={6} xl={6}>
                    <Row className='justify-end'>
                      {userDetails.role === 'manager' ||
                      userDetails.role === 'executive' ? (
                        <Col md={3} lg={3} sm={12}>
                          <Link to={`lead/${resid}`}>
                            <button className='btn add-btn text-white'>
                              + Create Lead
                            </button>
                          </Link>
                        </Col>
                      ) : (
                        ''
                      )}

                      {/* <Col md={3} lg={3} sm={12} className='send-email'>
                        <button className='btn add-btn text-white'>
                          <FontAwesomeIcon className='me-1' icon={faEnvelope} />
                          Send Emails
                        </button>
                      </Col> */}
                      <Col md={3} lg={3} sm={12} className='send-email'>
                        <Link to={`/client_contact/edit_client/${resid}`}>
                          <FontAwesomeIcon
                            className='edit-org mt-1 me-1'
                            icon={faPenToSquare}
                          />
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col md={3}>
            <Card className='p-2'>
              <CardBody>
                <h6>Basic Information</h6>
                <p className='mt-3 '>
                  <FontAwesomeIcon
                    className='text-blue-500 mr-3'
                    icon={faEnvelope}
                  />{' '}
                  {companyProfile?.contactPersons?.[0]?.email}
                </p>
                <p className='mt-3'>
                  <FontAwesomeIcon
                    className='text-blue-500 mr-3'
                    icon={faPhone}
                  />{' '}
                  {companyProfile?.phoneNumber}
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col md={9} className='right-section'>
            <Card>
              <CardBody>
                <div className='d-flex justify-content-between'>
                  <div
                    className={`client-activities ${
                      activeBtn === 'contact' ? 'active' : ''
                    } p-1 col-md-3 col-sm-3 text-center`}
                  >
                    <Link
                      className={`tab ${
                        activeBtn === 'contact' ? 'active' : ''
                      }`}
                    >
                      <span onClick={() => handleBtnClick('contact')}>
                        Contact
                      </span>
                    </Link>
                  </div>
                  <div
                    className={`client-activities ${
                      activeBtn === 'lead' ? 'active' : ''
                    } p-1 col-md-3 col-sm-3 text-center`}
                  >
                    <Link
                      className={`tab ${activeBtn === 'lead' ? 'active' : ''}`}
                    >
                      <span onClick={() => handleBtnClick('lead')}>Leads</span>
                    </Link>
                  </div>
                  <div
                    className={`client-activities ${
                      activeBtn === 'deal' ? 'active' : ''
                    } p-1 col-md-3 col-sm-3 text-center`}
                  >
                    <Link
                      className={`tab ${activeBtn === 'deal' ? 'active' : ''}`}
                    >
                      <span onClick={() => handleBtnClick('deal')}>Deal</span>
                    </Link>
                  </div>
                </div>
              </CardBody>
            </Card>
            <TabContent activeTab={activeBtn}>
              <TabPane tabId='contact'>
                <Card className='mt-3'>
                  <CardBody>
                    <Row>
                      {companyProfile?.contactPersons?.map((data, index) => {
                        return (
                          <Col
                            md={4}
                            key={index}
                            className={index > 2 ? 'mt-3' : ''}
                          >
                            <Card className='shadow-md'>
                              <CardBody>
                                <h4>{data.name}</h4>
                                <p className='mt-2'>
                                  <FontAwesomeIcon
                                    icon={faPhone}
                                    className='text-blue-500 mr-2'
                                  />{' '}
                                  {data.phoneNumber}
                                </p>
                                <p>
                                  <FontAwesomeIcon
                                    icon={faEnvelope}
                                    className='text-blue-500 mr-2'
                                  />{' '}
                                  {data.email}
                                </p>
                                <p>
                                  <FontAwesomeIcon
                                    icon={faBriefcase}
                                    className='text-blue-500 mr-2'
                                  />{' '}
                                  {data.designation}
                                </p>
                              </CardBody>
                            </Card>
                          </Col>
                        )
                      })}
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId='lead'>
                <Card className='mt-3'>
                  <CardBody>
                    <Row>
                      {companyProfile?.leads?.map((data, index) => {
                        const dateString =
                          data.enquiryDetails.tentativeClosureDate
                        const date = new Date(dateString)

                        const formattedDate = date.toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric'
                        })
                        return (
                          <Col
                            md={4}
                            key={index}
                            className={index > 2 ? 'mt-3' : ''}
                            id='lead-cards'
                          >
                            <Card className='shadow-md'>
                              <CardBody className='body-card'>
                                <div className='d-flex justify-content-between'>
                                  <Link
                                    to={`lead_details/${data.id}`}
                                    className='text-dark no-underline'
                                  >
                                    <h4>
                                      {data.leadJourneyName.length > 13
                                        ? `${data.leadJourneyName.slice(
                                            0,
                                            13
                                          )}...`
                                        : data.leadJourneyName}
                                    </h4>
                                  </Link>
                                </div>
                                <span className='pr-2 pl-2 pt-1 pb-1 bg-blue-100 text-blue-600 rounded-full text-xs'>
                                  Thermax
                                </span>
                                <div className='d-flex mt-3 justify-content-between'>
                                  <span>
                                    <label className='text-sm text-gray-400'>
                                      Value
                                    </label>
                                    <p className='font-medium'>
                                      {
                                        data.productRequirements
                                          .proposedDealAmount
                                      }
                                    </p>
                                  </span>
                                  <span>
                                    <label className='text-sm text-gray-400'>
                                      Date
                                    </label>
                                    <p className='font-medium'>
                                      {formattedDate === 'Invalid Date'
                                        ? '-'
                                        : formattedDate}
                                    </p>
                                  </span>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        )
                      })}
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId='deal'>
                <Card className='mt-3'>
                  <CardBody>
                    <Row>
                      {companyProfile?.leads?.map((data, index) => {
                        const dateString =
                          data.enquiryDetails.tentativeClosureDate
                        const date = new Date(dateString)

                        const formattedDate = date.toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric'
                        })
                        return data.enquiryDetails.status === 'done' ? (
                          <Col
                            md={4}
                            key={index}
                            className={index > 2 ? 'mt-3' : ''}
                            id='lead-cards'
                          >
                            <Card className='shadow-md'>
                              <CardBody>
                                <div className='d-flex justify-content-between'>
                                  <Link
                                    to={`lead_details/${data.id}`}
                                    className='text-dark no-underline'
                                  >
                                    <h4>
                                      {data.leadJourneyName.length > 13
                                        ? `${data.leadJourneyName.slice(
                                            0,
                                            13
                                          )}...`
                                        : data.leadJourneyName}
                                    </h4>
                                  </Link>
                                  <span
                                    className={`${
                                      data.agreement.stage === 'WON'
                                        ? 'text-green-500'
                                        : data.agreement.stage === 'LOST' ||
                                          data.agreement.stage === 'DROP'
                                        ? 'text-red-600'
                                        : 'text-blue-500'
                                    } font-medium text-xs`}
                                  >
                                    {data.agreement.stage || 'IN PROGRESS'}
                                  </span>
                                </div>
                                <span className='pr-2 pl-2 pt-1 pb-1 bg-blue-100 text-blue-600 rounded-full text-xs'>
                                  Thermax
                                </span>
                                <div className='d-flex mt-3 justify-content-between'>
                                  <span>
                                    <label className='text-sm text-gray-400'>
                                      Value
                                    </label>
                                    <p className='font-medium'>
                                      {
                                        data.productRequirements
                                          .proposedDealAmount
                                      }
                                    </p>
                                  </span>
                                  <span>
                                    <label className='text-sm text-gray-400'>
                                      Date
                                    </label>
                                    <p className='font-medium'>
                                      {formattedDate}
                                    </p>
                                  </span>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ) : (
                          ''
                        )
                      })}
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

export default ClientProfile
