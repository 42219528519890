import React from 'react'
import Logo from '../../images/maskable_icon.png'
const PageLoader = () => {
  return (
    <div className='loader-overlay'>
      <div className='loader'></div>
    </div>
  )
}

export default PageLoader
