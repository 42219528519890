import React, { Fragment, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import 'primereact/resources/themes/lara-light-cyan/theme.css'
import { Toast } from 'primereact/toast'
import { API_URL } from '../../../utils/Constants/URLS'
import Modal from 'react-bootstrap/Modal'

const EditMarketingPlatform = props => {
  const { id, info } = props
  const toast = useRef(null)
  const token = localStorage.getItem('token')
  const navigate = useNavigate()
  const [modalMessage, setModalMessage] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const [influencerIdentified, setInfluencerIdentified] = useState(
    info?.influencerIdentified
  )
  const [name, setName] = useState(info?.contactPerson?.name)
  const [email, setEmail] = useState(info?.contactPerson?.email)
  const [phoneNumber, setPhoneNumber] = useState(
    info?.contactPerson?.phoneNumber
  )
  const [designation, setDesignation] = useState(
    info?.contactPerson?.designation
  )
  const [equipmentConfirmed, setEquipmentConfirmed] = useState(
    info?.equipmentConfirmed
  )
  const [submittedFirstOffer, setSubmittedFirstOffer] = useState(
    info?.submittedFirstOffer
  )
  const [competitors, setCompititors] = useState(info?.competitors)
  const [statutoryRequirementsIdentified, setStatutoryRequirementsIdentified] =
    useState(info?.statutoryRequirementsIdentified)
  const [anyPastMeeting, setAnyPastMeeting] = useState(info?.anyPastMeeting)
  const [meetingDate, setMeetingDate] = useState(info?.meetingDate)
  const [meetingNotes, setMeetingNotes] = useState(info?.meetingNotes)

  const handleCloseModal = () => {
    setShowSuccessModal(false)
    setShowDeclineModal(false)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (influencerIdentified === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select Influencer Identified or Not',
        life: 3000
      })
    } else if (influencerIdentified === 'true' && name === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter Influencer Name',
        life: 3000
      })
    } else if (influencerIdentified === 'true' && phoneNumber === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter Influencer Phone',
        life: 3000
      })
    } else if (influencerIdentified === 'true' && designation === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter Influencer Designation',
        life: 3000
      })
    } else if (influencerIdentified === 'true' && email === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter Influencer Email',
        life: 3000
      })
    } else if (equipmentConfirmed === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select Equipment Confirmed or Not',
        life: 3000
      })
    } else if (submittedFirstOffer === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please 1st Offer Submitted or Not',
        life: 3000
      })
    } else if (competitors === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter Competitors Details',
        life: 3000
      })
    } else if (statutoryRequirementsIdentified === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select Statutory Requirements Identified',
        life: 3000
      })
    } else if (anyPastMeeting === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select Any Past Meeting',
        life: 3000
      })
    } else if (anyPastMeeting === 'true' && meetingDate === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select Meeting Date',
        life: 3000
      })
    } else if (anyPastMeeting === 'true' && meetingNotes === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select Meeting Notes',
        life: 3000
      })
    } else {
      try {
        const response = await fetch(`${API_URL}leads/${id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            marketingPlatform: {
              influencerIdentified,
              contactPerson: {
                name,
                email,
                phoneNumber,
                designation
              },
              equipmentConfirmed,
              submittedFirstOffer,
              competitors,
              statutoryRequirementsIdentified,
              anyPastMeeting,
              meetingDate,
              meetingNotes,
              status: 'done'
            }
          })
        })

        const res = await response.json()
        if (res.error === false) {
          setModalMessage('Marketing Platform Updated Successfully')
          setShowSuccessModal(true)
        } else {
          setModalMessage('Something Went Wrong')
          setShowDeclineModal(true)
        }
      } catch (error) {
        setModalMessage('Oops! Error While Adding Data')
        setShowDeclineModal(true)
      }
    }
  }

  return (
    <Fragment>
      <div>
        <form className='row g-3 needs-validation' noValidate>
          <div className='row mt-3 pb-2'>
            <div className='col-md-4'>
              <label htmlFor='influencerIndentified' className='form-label'>
                Identified Influencer <span className='text-danger'>*</span>
              </label>
              <br />
              <div className='form-check form-check-inline'>
                <input
                  name='influencerIndentified'
                  value={true}
                  onChange={e => setInfluencerIdentified(e.target.value)}
                  checked={influencerIdentified === true}
                  className='form-check-input'
                  type='radio'
                  id='identified-influencer-yes'
                />
                <label
                  className='form-check-label'
                  htmlFor='identified-influencer-yes'
                >
                  Yes
                </label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  name='influencerIndentified'
                  value={false}
                  onChange={e => setInfluencerIdentified(e.target.value)}
                  checked={influencerIdentified === false}
                  className='form-check-input'
                  type='radio'
                  id='identified-influencer-no'
                />
                <label
                  className='form-check-label'
                  htmlFor='identified-influencer-no'
                >
                  No
                </label>
              </div>
            </div>
          </div>

          {influencerIdentified === 'true' ? (
            <div className='row mt-3 border-t-2 border-b-2 pb-3 pt-2'>
              <h5>Influencer Details</h5>
              <div className='col-md-6'>
                <label htmlFor='name' className='form-label'>
                  Name <span className='text-danger'>*</span>
                </label>
                <input
                  name='name'
                  value={name}
                  onChange={e => setName(e.target.value)}
                  type='text'
                  className='form-control'
                  data-section='contactPerson'
                />
              </div>
              <div className='col-md-6'>
                <label htmlFor='phoneNumber' className='form-label'>
                  Mobile Number <span className='text-danger'>*</span>
                </label>
                <input
                  name='phoneNumber'
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                  type='text'
                  className='form-control'
                  data-section='contactPerson'
                />
              </div>
              <div className='col-md-6'>
                <label htmlFor='designation' className='form-label'>
                  Designation <span className='text-danger'>*</span>
                </label>
                <input
                  name='designation'
                  value={designation}
                  onChange={e => setDesignation(e.target.value)}
                  type='text'
                  className='form-control'
                  data-section='contactPerson'
                />
              </div>
              <div className='col-md-6'>
                <label htmlFor='email' className='form-label'>
                  Email <span className='text-danger'>*</span>
                </label>
                <input
                  name='email'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  type='email'
                  className='form-control'
                  data-section='contactPerson'
                />
              </div>
            </div>
          ) : (
            ''
          )}

          <div className='row mt-3'>
            <div className='col-md-4'>
              <label htmlFor='equipmentConfirmed' className='form-label'>
                Confirmed Equipment <span className='text-danger'>*</span>
              </label>
              <br />
              <div className='form-check form-check-inline'>
                <input
                  value={true}
                  onChange={e => setEquipmentConfirmed(e.target.value)}
                  checked={equipmentConfirmed === true}
                  className='form-check-input'
                  type='radio'
                  name='equipmentConfirmed'
                  id='confirm-equipment-yes'
                />
                <label
                  className='form-check-label'
                  htmlFor='confirm-equipment-yes'
                >
                  Yes
                </label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  value={false}
                  onChange={e => setEquipmentConfirmed(e.target.value)}
                  checked={equipmentConfirmed === false}
                  className='form-check-input'
                  type='radio'
                  name='equipmentConfirmed'
                  id='confirm-equipment-no'
                />
                <label
                  className='form-check-label'
                  htmlFor='confirm-equipment-no'
                >
                  No
                </label>
              </div>
            </div>
            <div className='col-md-4'>
              <label htmlFor='submittedFirstOffer' className='form-label'>
                Submitted 1st Offer <span className='text-danger'>*</span>
              </label>
              <br />
              <div className='form-check form-check-inline'>
                <input
                  value={true}
                  onChange={e => setSubmittedFirstOffer(e.target.value)}
                  className='form-check-input'
                  type='radio'
                  name='submittedFirstOffer'
                  id='submitted-offer-yes'
                />
                <label
                  className='form-check-label'
                  htmlFor='submitted-offer-yes'
                >
                  Yes
                </label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  value={false}
                  onChange={e => setSubmittedFirstOffer(e.target.value)}
                  className='form-check-input'
                  type='radio'
                  name='submittedFirstOffer'
                  id='submitted-offer-no'
                />
                <label
                  className='form-check-label'
                  htmlFor='submitted-offer-no'
                >
                  No
                </label>
              </div>
            </div>
            <div className='col-md-4'>
              <label htmlFor='competitors' className='form-label'>
                Competitors <span className='text-danger'>*</span>
              </label>
              <input
                name='competitors'
                value={competitors}
                onChange={e => setCompititors(e.target.value)}
                type='text'
                className='form-control'
              />
            </div>

            <div className='col-md-6 col-sm-12'>
              <label
                htmlFor='statutoryRequirementsIdentified'
                className='form-label'
              >
                Statutory Requirement Identified
                <span className='text-danger'>*</span>
              </label>
              <br />
              <div className='form-check form-check-inline'>
                <input
                  value={true}
                  onChange={e =>
                    setStatutoryRequirementsIdentified(e.target.value)
                  }
                  className='form-check-input'
                  type='radio'
                  name='statutoryRequirementsIdentified'
                  id='s-req-identified-yes'
                />
                <label
                  className='form-check-label'
                  htmlFor='s-req-identified-yes'
                >
                  Yes
                </label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  value={false}
                  onChange={e =>
                    setStatutoryRequirementsIdentified(e.target.value)
                  }
                  className='form-check-input'
                  type='radio'
                  name='statutoryRequirementsIdentified'
                  id='s-req-identified-no'
                />
                <label
                  className='form-check-label'
                  htmlFor='s-req-identified-no'
                >
                  No
                </label>
              </div>
            </div>
            <div className='col-md-6'>
              <label htmlFor='anyPastMeeting' className='form-label'>
                Any Past Meeting? <span className='text-danger'>*</span>
              </label>
              <br />
              <div className='form-check form-check-inline'>
                <input
                  value={true}
                  onChange={e => setAnyPastMeeting(e.target.value)}
                  className='form-check-input'
                  type='radio'
                  name='anyPastMeeting'
                  id='past-meeting-yes'
                />
                <label className='form-check-label' htmlFor='past-meeting-yes'>
                  Yes
                </label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  value={false}
                  onChange={e => setAnyPastMeeting(e.target.value)}
                  className='form-check-input'
                  type='radio'
                  name='anyPastMeeting'
                  id='past-meeting-no'
                />
                <label className='form-check-label' htmlFor='past-meeting-no'>
                  No
                </label>
              </div>
            </div>
          </div>

          {anyPastMeeting === 'true' ? (
            <div className='row mt-3'>
              <div className='col-md-6'>
                <label htmlFor='meetingDate' className='form-label'>
                  Date and Time of Meeting{' '}
                  <span className='text-danger'>*</span>
                </label>
                <input
                  name='meetingDate'
                  value={meetingDate}
                  onChange={e => setMeetingDate(e.target.value)}
                  min='2022-01-01'
                  max='2099-12-31'
                  className='form-control'
                  type='date'
                  id='meetingDate'
                  onClick={e => e.target.showPicker && e.target.showPicker()}
                />
              </div>
              <div className='col-md-6'>
                <label htmlFor='meetingNotes' className='form-label'>
                  Meeting Notes
                </label>
                <textarea
                  name='meetingNotes'
                  value={meetingNotes}
                  onChange={e => setMeetingNotes(e.target.value)}
                  className='form-control'
                  id='meetingNotes'
                />
              </div>
            </div>
          ) : (
            ''
          )}

          <div className='col-md-12 d-flex justify-content-between pt-2'>
            <button
              className='btn add-btn text-white'
              onClick={handleSubmit}
              type='submit'
            >
              Edit
            </button>
          </div>
        </form>
        <Modal show={showSuccessModal} centered>
          <Modal.Body>
            <div class='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 shrink-0 fill-green-500 inline'
                viewBox='0 0 512 512'
              >
                <path
                  d='M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z'
                  data-original='#000000'
                />
                <path
                  d='M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z'
                  data-original='#000000'
                />
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                Good Job
              </h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                {modalMessage}!
              </p>
            </div>

            <button
              type='button'
              onClick={handleCloseModal}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-green-500 hover:bg-green-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
        <Modal show={showDeclineModal} centered>
          <Modal.Body>
            <div className='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 fill-red-500 inline'
                viewBox='0 0 286.054 286.054'
              >
                <path
                  fill='#e2574c'
                  d='M143.027 0C64.04 0 0 64.04 0 143.027c0 78.996 64.04 143.027 143.027 143.027 78.996 0 143.027-64.022 143.027-143.027C286.054 64.04 222.022 0 143.027 0zm0 259.236c-64.183 0-116.209-52.026-116.209-116.209S78.844 26.818 143.027 26.818s116.209 52.026 116.209 116.209-52.026 116.209-116.209 116.209zm.009-196.51c-10.244 0-17.995 5.346-17.995 13.981v79.201c0 8.644 7.75 13.972 17.995 13.972 9.994 0 17.995-5.551 17.995-13.972V76.707c-.001-8.43-8.001-13.981-17.995-13.981zm0 124.997c-9.842 0-17.852 8.01-17.852 17.86 0 9.833 8.01 17.843 17.852 17.843s17.843-8.01 17.843-17.843c-.001-9.851-8.001-17.86-17.843-17.86z'
                  data-original='#e2574c'
                ></path>
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                Error Occured
              </h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                {modalMessage}!
              </p>
            </div>

            <button
              type='button'
              onClick={handleCloseModal}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-red-500 hover:bg-red-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
        <Toast ref={toast} />
      </div>
    </Fragment>
  )
}

export default EditMarketingPlatform
