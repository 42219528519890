import React, { Fragment, useState, useRef } from 'react'
import 'primereact/resources/themes/lara-light-cyan/theme.css'
import { Toast } from 'primereact/toast'
import { API_URL } from '../../utils/Constants/URLS'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const EnquiryDetails = props => {
  const leadDetails = useSelector(store => store.deal)
  const navigate = useNavigate()
  const { id } = props
  const toast = useRef(null)
  const token = localStorage.getItem('token')
  const [modalMessage, setModalMessage] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const [data, setdata] = useState({
    enquiryDetails: {
      projectType: '',
      contactEstablished: null,
      informationReceived: null,
      furtherInformation: '',
      userIdentified: null,
      anyPastMeeting: null,
      meetingDate: '',
      meetingNotes: '',
      tentativeClosureDate: '',
      needIdentified: null,
      terminateLead: null,
      status: 'done'
    }
  })

  const handleCloseModal = () => {
    setShowSuccessModal(false)
    setShowDeclineModal(false)
    navigate('/view_leads')
  }

  // Handle input changes for text inputs
  const handleInputChange = event => {
    const { name, value, dataset } = event.target
    setdata(prevData => ({
      ...prevData,
      [dataset.section]: {
        ...prevData[dataset.section],
        [name]: value
      }
    }))
  }

  const handleRadioChange = event => {
    const { name, value, dataset } = event.target
    setdata(prevData => ({
      ...prevData,
      [dataset.section]: {
        ...prevData[dataset.section],
        [name]: value === 'true' // Assuming you want to set it as a boolean
      }
    }))
  }

  const handleUpdateEnquiryDetails = async e => {
    e.preventDefault()
    if (
      leadDetails.productRequirements.natureOfSale === 'project' &&
      data.enquiryDetails.projectType === ''
    ) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select The Project Type',
        life: 3000
      })
    } else if (data.enquiryDetails.contactEstablished === null) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select Contact Established Or Not',
        life: 3000
      })
    } else if (data.enquiryDetails.informationReceived === null) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select Information Received or Not',
        life: 3000
      })
    } else if (data.enquiryDetails.userIdentified === null) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select User Identified or Not',
        life: 3000
      })
    } else if (data.enquiryDetails.anyPastMeeting === null) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select Past Meeting Done or Not',
        life: 3000
      })
    } else if (
      data.enquiryDetails.anyPastMeeting === true &&
      data.enquiryDetails.meetingDate === ''
    ) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter the Meeting Date',
        life: 3000
      })
    } else if (
      data.enquiryDetails.anyPastMeeting === true &&
      data.enquiryDetails.meetingNotes === ''
    ) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter the Meeting Note',
        life: 3000
      })
    } else if (data.enquiryDetails.tentativeClosureDate === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter Tentative Closure Date',
        life: 3000
      })
    } else if (data.enquiryDetails.needIdentified === null) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select Need Identified or Not',
        life: 3000
      })
    } else {
      try {
        const res = await fetch(`${API_URL}leads/${id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            enquiryDetails: data.enquiryDetails
          })
        })
        const result = await res.json()
        if (result.error === false) {
          setModalMessage('Enquiry Details updated successfully')
          setShowSuccessModal(true)
        } else {
          setModalMessage('Something Went Wrong')
          setShowDeclineModal(true)
        }
      } catch (e) {
        setModalMessage('Oops! Unable to add data')
        setShowDeclineModal(true)
      }
    }
  }

  return (
    <Fragment>
      <div>
        <form className='row g-3 needs-validation' noValidate>
          {leadDetails?.productRequirements?.natureOfSale === 'project' ? (
            <div className='col-md-4'>
              <label for='name' className='form-label'>
                Project Type <span className='text-danger'>*</span>
              </label>
              <select
                data-section='enquiryDetails'
                name='projectType'
                value={data.enquiryDetails.projectType}
                onChange={handleInputChange}
                className='form-select'
              >
                <option value=''>Select</option>
                <option value='New'>New</option>
                <option value='Expansion'>Expansion</option>
                <option value='Modernization'>Modernization</option>
              </select>
            </div>
          ) : (
            <div className='col-md-4'>
              <label for='name' className='form-label'>
                Product <span className='text-danger'>*</span>
              </label>
              <input
                className='form-control bg-gray-400'
                type='text'
                name='contactEstablished'
                readOnly
              />
            </div>
          )}

          <div className='col-md-4'>
            <label for='name' className='form-label'>
              Contact Established <span className='text-danger'>*</span>
            </label>
            <br />
            <div className='form-check form-check-inline'>
              <input
                type='radio'
                name='contactEstablished'
                value={true}
                checked={data.enquiryDetails.contactEstablished === true}
                onChange={handleRadioChange}
                data-section='enquiryDetails'
              />
              <label className='form-check-label' for='inlineCheckbox1'>
                Yes
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                type='radio'
                name='contactEstablished'
                value={false}
                checked={data.enquiryDetails.contactEstablished === false}
                onChange={handleRadioChange}
                data-section='enquiryDetails'
              />
              <label className='form-check-label' for='inlineCheckbox1'>
                No
              </label>
            </div>
          </div>
          <div className='col-md-4'>
            <label for='name' className='form-label'>
              Information Received <span className='text-danger'>*</span>
            </label>
            <br />
            <div className='form-check form-check-inline'>
              <input
                type='radio'
                name='informationReceived'
                value={true}
                checked={data.enquiryDetails.informationReceived === true}
                onChange={handleRadioChange}
                data-section='enquiryDetails'
              />
              <label className='form-check-label' for='info-received'>
                Yes
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                type='radio'
                name='informationReceived'
                value={false}
                checked={data.enquiryDetails.informationReceived === false}
                onChange={handleRadioChange}
                data-section='enquiryDetails'
              />
              <label className='form-check-label' for='info-received'>
                No
              </label>
            </div>
          </div>
          <div className='col-md-6'>
            <label for='further-info' className='form-label'>
              Further Info to be added?
            </label>
            <input
              name='furtherInformation'
              value={data.enquiryDetails.furtherInformation}
              onChange={handleInputChange}
              type='text'
              data-section='enquiryDetails'
              className='form-control'
              id='enquiry-details'
            />
          </div>
          <div className='col-md-3'>
            <label for='name' className='form-label'>
              Identified User <span className='text-danger'>*</span>
            </label>
            <br />
            <div className='form-check form-check-inline'>
              <input
                type='radio'
                name='userIdentified'
                value={true}
                checked={data.enquiryDetails.userIdentified === true}
                onChange={handleRadioChange}
                data-section='enquiryDetails'
              />
              <label className='form-check-label' for='inlineCheckbox1'>
                Yes
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                type='radio'
                name='userIdentified'
                value={false}
                checked={data.enquiryDetails.userIdentified === false}
                onChange={handleRadioChange}
                data-section='enquiryDetails'
              />
              <label className='form-check-label' for='inlineCheckbox1'>
                No
              </label>
            </div>
          </div>
          <div className='col-md-3'>
            <label for='name' className='form-label'>
              Any Past Meeting? <span className='text-danger'>*</span>
            </label>
            <br />
            <div className='form-check form-check-inline'>
              <input
                type='radio'
                name='anyPastMeeting'
                value={true}
                checked={data.enquiryDetails.anyPastMeeting === true}
                onChange={handleRadioChange}
                data-section='enquiryDetails'
              />
              <label className='form-check-label' for='inlineCheckbox1'>
                Yes
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                type='radio'
                name='anyPastMeeting'
                value={false}
                checked={data.enquiryDetails.anyPastMeeting === false}
                onChange={handleRadioChange}
                data-section='enquiryDetails'
              />
              <label className='form-check-label' for='inlineCheckbox1'>
                No
              </label>
            </div>
          </div>
          {data.enquiryDetails.anyPastMeeting === true ? (
            <Fragment>
              <div className='col-md-6'>
                <label for='name' className='form-label'>
                  Date and time of meeting{' '}
                  <span className='text-danger'>*</span>
                </label>
                <input
                  name='meetingDate'
                  onClick={(e) => e.target.showPicker && e.target.showPicker()}
                  value={data.enquiryDetails.meetingDate}
                  onChange={handleInputChange}
                  className='form-control'
                  type='date'
                  id='Test_DatetimeLocal'
                  data-section='enquiryDetails'
                />
              </div>
              <div className='col-md-6'>
                <label for='enquiry-details' className='form-label'>
                  Meeting Notes <span className='text-danger'>*</span>
                </label>
                <textarea
                  name='meetingNotes'
                  value={data.enquiryDetails.meetingNotes}
                  onChange={handleInputChange}
                  type='text'
                  data-section='enquiryDetails'
                  className='form-control'
                  id='enquiry-details'
                />
              </div>
            </Fragment>
          ) : null}

          <div className='col-md-6'>
            <label for='name' className='form-label'>
              Tentative Closure Date <span className='text-danger'>*</span>
            </label>
            <input
              name='tentativeClosureDate'
              value={data.enquiryDetails.tentativeClosureDate}
              onChange={handleInputChange}
              onClick={(e) => e.target.showPicker && e.target.showPicker()}
              className='form-control'
              min='2024-12-13'
              max='2099-12-31'
              type='date'
              id='Test_DatetimeLocal'
              data-section='enquiryDetails'
            />
          </div>

          <div className='col-md-6'>
            <label for='name' className='form-label'>
              Need Identified <span className='text-danger'>*</span>
            </label>
            <br />
            <div className='form-check form-check-inline'>
              <input
                type='radio'
                name='needIdentified'
                value={true}
                checked={data.enquiryDetails.needIdentified === true}
                onChange={handleRadioChange}
                data-section='enquiryDetails'
              />
              <label className='form-check-label' for='inlineCheckbox1'>
                Yes
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                type='radio'
                name='needIdentified'
                value={false}
                checked={data.enquiryDetails.needIdentified === false}
                onChange={handleRadioChange}
                data-section='enquiryDetails'
              />
              <label className='form-check-label' for='flexRadioDefault1'>
                No
              </label>
            </div>
          </div>

          <div className='col-md-12 d-flex justify-content-end'>
            <button
              onClick={handleUpdateEnquiryDetails}
              className='btn add-btn text-white'
              type='button'
            >
              Submit
            </button>
          </div>
        </form>
        <Modal show={showSuccessModal} centered>
          <Modal.Body>
            <div className='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 shrink-0 fill-green-500 inline'
                viewBox='0 0 512 512'
              >
                <path
                  d='M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z'
                  data-original='#000000'
                />
                <path
                  d='M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z'
                  data-original='#000000'
                />
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                Good Job
              </h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                {modalMessage}!
              </p>
            </div>

            <button
              type='button'
              onClick={handleCloseModal}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-green-500 hover:bg-green-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
        <Modal show={showDeclineModal} centered>
          <Modal.Body>
            <div className='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 fill-red-500 inline'
                viewBox='0 0 286.054 286.054'
              >
                <path
                  fill='#e2574c'
                  d='M143.027 0C64.04 0 0 64.04 0 143.027c0 78.996 64.04 143.027 143.027 143.027 78.996 0 143.027-64.022 143.027-143.027C286.054 64.04 222.022 0 143.027 0zm0 259.236c-64.183 0-116.209-52.026-116.209-116.209S78.844 26.818 143.027 26.818s116.209 52.026 116.209 116.209-52.026 116.209-116.209 116.209zm.009-196.51c-10.244 0-17.995 5.346-17.995 13.981v79.201c0 8.644 7.75 13.972 17.995 13.972 9.994 0 17.995-5.551 17.995-13.972V76.707c-.001-8.43-8.001-13.981-17.995-13.981zm0 124.997c-9.842 0-17.852 8.01-17.852 17.86 0 9.833 8.01 17.843 17.852 17.843s17.843-8.01 17.843-17.843c-.001-9.851-8.001-17.86-17.843-17.86z'
                  data-original='#e2574c'
                ></path>
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                Error Occured
              </h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                {modalMessage}!
              </p>
            </div>

            <button
              type='button'
              onClick={handleCloseModal}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-red-500 hover:bg-red-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
        <Toast ref={toast} />
      </div>
    </Fragment>
  )
}

export default EnquiryDetails
